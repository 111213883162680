import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import PopupCard from 'src/components/common/PopupCard';
import ScanPermitContainer from 'src/containers/user/Transportation-container/scan-permit-container';

export default function ScanPermitPage() {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <PopupCard
        link={`${appRoutesObj.getBasePath()}`}
        icon={<IoMdClose size={32} className="text-white" />}
      >
        <>
          <div
            className={`text-white justify-center text-center w-full pb-10 ${
              i18n.language == 'en' ? 'font-helveticaBold text-lg' : 'font-arMyriad text-[28px]'
            }`}
          >
            {t('app.shared.TransportationDetails')}{' '}
          </div>
          <ScanPermitContainer />
        </>
      </PopupCard>
    </Layout>
  );
}
