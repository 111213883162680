import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiEyeLine, RiEyeOffLine, RiLock2Line } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import Loading from 'src/components/common/Loading/loading';
import { Button } from 'src/components/common/ui/Button';
import InputFieldV2 from 'src/components/common/ui/InputFieldV2';
import LoginCardComponent from 'src/components/user/login-card-compoent';
import { ResetStatusTypesEnum } from 'src/core/enums/service.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { getResetPasswordStatusService, putResetPasswordService } from 'src/services/user.service';

import { resetPasswordInitialValues } from './reset-password-form.data';
import { resetPasswordValidationSchema } from './reset-password-form.validation';

function ResetPasswordContainer() {
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [enableButton, setEnableButton] = useState<boolean>(false);
  const [isResetOpen, setIsResetOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const location = useLocation();

  const getKeyFromQuery = (): string => {
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get('key') ?? '';
  };

  const key: string = getKeyFromQuery();

  const checkResetPasswordStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const resetStatus = await getResetPasswordStatusService(key || '');

      if (resetStatus.reset_status === ResetStatusTypesEnum.OPEN) {
        setIsResetOpen(true);
      }
    } catch (error) {
      handleRequestError(error);
      setIsResetOpen(false);
    } finally {
      setIsLoading(false);
    }
  }, [key]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      setEnableButton(true);
      await putResetPasswordService(key, formik.values);
      formik.setSubmitting(false);
      navigate(appRoutesObj.getLoginPagePath());
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: resetPasswordInitialValues,
    validationSchema: resetPasswordValidationSchema(),
    onSubmit: async () => {
      await handleSubmit();
    },
  });

  useEffect(() => {
    if (key) {
      checkResetPasswordStatus();
    }
  }, [checkResetPasswordStatus, key]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center py-10 mobile:py-20 tablet:py-20 mx-auto">
        <LoginCardComponent>
          <Loading />
        </LoginCardComponent>
      </div>
    );
  }

  return (
    <>
      {isResetOpen ? (
        <div className="flex flex-col items-center justify-center py-10 mobile:py-20 tablet:py-20 mx-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <LoginCardComponent>
              <div className="flex flex-col h-full justify-center mobile:py-[89px] px-24 mobile:px-2 mobile:w-[calc(100vw-32px)]">
                <p
                  className={`text-white pt-6 ${
                    i18n.language === 'en'
                      ? 'text-3xl font-helveticaBold'
                      : 'text-4xl font-arMyriad'
                  }`}
                >
                  {t('container.ResetPasswordContainer.setPassword')}
                </p>
                <p
                  className={`text-white pt-2 pb-8 text-base ${
                    i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
                  }`}
                >
                  {t('container.ResetPasswordContainer.setAPasswordDescription')}
                </p>
                <div className="flex flex-col gap-2.5">
                  <InputFieldV2
                    name="password"
                    type={showPassword ? 'password' : 'text'}
                    label={`${t('app.shared.password')}`}
                    icon1={<RiLock2Line className="text-white" size={20} />}
                    icon2={
                      showPassword ? (
                        <RiEyeOffLine
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          className="text-gray-400 cursor-pointer"
                          size={20}
                        />
                      ) : (
                        <RiEyeLine
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          className="text-gray-400 cursor-pointer"
                          size={20}
                        />
                      )
                    }
                    isValid={formik.touched.password && !formik.errors.password}
                    value={formik.values.password != null ? formik.values.password : ''}
                    errorMsg={
                      formik.touched.password && formik.errors.password
                        ? formik.errors.password
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('password', e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                  <InputFieldV2
                    name="confirm_password"
                    type={showConfirmPassword ? 'password' : 'text'}
                    label={`${t('app.shared.confirmPassword')}`}
                    icon1={<RiLock2Line className="text-white" size={20} />}
                    icon2={
                      showConfirmPassword ? (
                        <RiEyeOffLine
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                          className="text-gray-400 cursor-pointer"
                          size={20}
                        />
                      ) : (
                        <RiEyeLine
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                          className="text-gray-400 cursor-pointer"
                          size={20}
                        />
                      )
                    }
                    isValid={formik.touched.confirm_password && !formik.errors.confirm_password}
                    value={
                      formik.values.confirm_password != null ? formik.values.confirm_password : ''
                    }
                    errorMsg={
                      formik.touched.confirm_password && formik.errors.confirm_password
                        ? formik.errors.confirm_password
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('confirm_password', e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                </div>

                <Button
                  disabled={enableButton}
                  className={`flex flex-row items-center justify-center h-10 w-full my-5 ${
                    i18n.language === 'en'
                      ? 'font-helveticaBold text-xs'
                      : 'font-arMyriad text-base'
                  }`}
                  type="submit"
                >
                  {t('container.ResetPasswordContainer.setPassword')}
                </Button>
              </div>
            </LoginCardComponent>
          </form>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-20 mx-auto">
          <LoginCardComponent>
            <div className="flex flex-col justify-center items-center mx-auto h-full w-[354px] mobile:py-[150px] mobile:px-12 mobile:w-[calc(100vw-32px)]">
              <div
                className={`text-white ${
                  i18n.language === 'en' ? 'text-3xl font-helveticaBold' : 'text-4xl font-arMyriad'
                } self-start`}
              >
                {t('container.ResetPasswordContainer.title')}
              </div>
              <div
                className={`text-white pt-2 self-start ${
                  i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
                } text-base`}
              >
                {t('container.ResetPasswordContainer.description')}
              </div>
              <Button
                className={`flex flex-row items-center justify-center h-10 w-full my-6 ${
                  i18n.language === 'en' ? 'font-helveticaBold text-xs' : 'font-arMyriad text-base'
                }`}
                onClick={() => {
                  navigate(appRoutesObj.getBasePath());
                }}
              >
                {t('container.ResetPasswordContainer.backHome')}
              </Button>
            </div>
          </LoginCardComponent>
        </div>
      )}
    </>
  );
}

export default ResetPasswordContainer;
