import { onlyLetters_Ar_En } from 'src/core/statics/regex';
import * as Yup from 'yup';

export const validationSchema = () => {
  const thirtyYearsAgo = new Date();
  thirtyYearsAgo.setFullYear(thirtyYearsAgo.getFullYear() - 30);

  return Yup.object({
    name: Yup.string()
      .matches(onlyLetters_Ar_En, 'Company name cannot contain special characters.')
      .nullable(),
    description: Yup.string().nullable().max(500),
    industry: Yup.string().nullable(),
    location: Yup.string().nullable(),
    start_date: Yup.date()
      .max(new Date(), 'Start date cannot be in the future')
      .min(thirtyYearsAgo, 'Start date cannot be more than 30 years ago')
      .nullable(),
    end_date: Yup.date()
      .min(Yup.ref('start_date'), 'Please mark as current or select an end date.')
      .nullable(),
    no_expired_date: Yup.boolean().default(false),
  });
};
