import { useFormik } from 'formik';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { ReportProblemButtonComponent } from 'src/components/common/report-problem-button-component';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import SelectComponent from 'src/components/common/ui/select-component';
import TextAreaField from 'src/components/common/ui/textArea';
import QRPopupComponent from 'src/components/qr-popup-component';
import { handleRequestError } from 'src/core/helpers/error.helper';
import * as list from 'src/core/statics/form-data/form-data';
import { disruptGuestApplication } from 'src/services/distrupt-guest.service';

import { initialValues } from './Attendees Apply/attendees-apply-form.data';
import { validationSchema } from './Attendees Apply/attendees-apply-form.validation';

export type PopUpHiddenDisrupt = {
  id: string;
  full_name: string;
  occupation: string;
  data: string;
};
export default function HiddenDisruptFormContainer() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { i18n } = useTranslation();
  const [popupData, setPopupData] = useState<PopUpHiddenDisrupt>();
  const [succeSsfully, setSucceSsfully] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(!isLoading);
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        await disruptGuestApplication(
          {
            first_name: formik.values.firstName,
            last_name: formik.values.lastName,
            email: formik.values.email.trim(),
            mobile: formik.values.phoneNo.trim(),
            gender: formik.values.gender,
            nationality: formik.values.nationality,
            position: formik.values.position,
            company_name: formik.values.companyName,
            occupation: formik.values.occupation,
            reason_to_join: formik.values.reasonToJoin,
            application_state: 'ACCEPTED',
            is_hidden_application: true,
          },
          token
        ).then((res) => {
          setPopupData(res);
        });
        setSucceSsfully(!succeSsfully);
      } else {
        handleRequestError("Couldn't verify Google Recaptcha, refresh the page please");
        return;
      }
    } catch (error) {
      handleRequestError(error);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: validationSchema,
  });

  return (
    <div>
      <GridView column={'2'}>
        <InputField
          label="app.shared.firstName"
          name="firstName"
          isValid={formik.touched.firstName && !formik.errors.firstName}
          value={formik.values.firstName != null ? formik.values.firstName : ''}
          errorMsg={
            formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''
          }
          onChange={(e) => {
            formik.setFieldValue('firstName', e.target.value);
            formik.handleChange(e);
          }}
        />
        <InputField
          label="app.shared.lastName"
          name="lastName"
          isValid={formik.touched.lastName && !formik.errors.lastName}
          value={formik.values.lastName != null ? formik.values.lastName : ''}
          errorMsg={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
          onChange={(e) => {
            formik.setFieldValue('lastName', e.target.value);
            formik.handleChange(e);
          }}
        />
        <InputField
          label="app.shared.mobile.number"
          name="phoneNo"
          errorMsg={formik.touched.phoneNo && formik.errors.phoneNo ? formik.errors.phoneNo : ''}
          minLength={12}
          isValid={formik.touched.phoneNo && !formik.errors.phoneNo}
          value={formik.values.phoneNo != null ? formik.values.phoneNo : NaN}
          onChange={(e) => {
            formik.setFieldValue('phoneNo', e.target.value);
            formik.handleChange(e);
          }}
        />

        <InputField
          label="app.shared.email.requierd"
          name="email"
          isValid={formik.touched.email && !formik.errors.email}
          value={formik.values.email != null ? formik.values.email : ''}
          errorMsg={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
          onChange={(e) => {
            formik.setFieldValue('email', e.target.value);
            formik.handleChange(e);
          }}
        />
        <InputField
          label="app.shared.nationalty"
          name="nationality"
          isValid={formik.touched.nationality && !formik.errors.nationality}
          value={formik.values.nationality != null ? formik.values.nationality : ''}
          errorMsg={
            formik.touched.nationality && formik.errors.nationality ? formik.errors.nationality : ''
          }
          onChange={(e) => {
            formik.setFieldValue('nationality', e.target.value);
            formik.handleChange(e);
          }}
        />
        <SelectComponent
          selectLabel="app.shared.gender"
          options={i18n.language === 'en' ? list.gender_en : list.gender_ar}
          value={formik.values.gender != null ? formik.values.gender : ''}
          errorMsg={formik.touched.gender && formik.errors.gender ? formik.errors.gender : ''}
          onChange={(e) => {
            formik.setFieldValue('gender', e.target.value);
            formik.handleChange(e);
          }}
          name="gender"
        />
        <SelectComponent
          selectLabel="app.shared.occupation"
          name="occupation"
          options={i18n.language === 'en' ? list.occupation_en : list.occupation_ar}
          isValid={formik.touched.occupation && !formik.errors.occupation}
          value={formik.values.occupation != null ? formik.values.occupation : ''}
          errorMsg={
            formik.touched.occupation && formik.errors.occupation ? formik.errors.occupation : ''
          }
          onChange={(e) => {
            formik.setFieldValue('occupation', e.target.value);
            formik.handleChange(e);
          }}
        />
        <InputField
          label="app.shared.company"
          name="companyName"
          isValid={formik.touched.companyName && !formik.errors.companyName}
          value={formik.values.companyName != null ? formik.values.companyName : ''}
          errorMsg={
            formik.touched.companyName && formik.errors.companyName ? formik.errors.companyName : ''
          }
          onChange={(e) => {
            formik.setFieldValue('companyName', e.target.value);
            formik.handleChange(e);
          }}
        />
        <div className="col-span-2 mobile:col-span-1 tablet:col-span-1">
          <div className="mb-4">
            <InputField
              label="app.shared.position"
              onChange={(e) => {
                formik.setFieldValue('position', e.target.value);
                formik.handleChange(e);
              }}
              value={formik.values.position != null ? formik.values.position : ''}
              errorMsg={
                formik.touched.position && formik.errors.position ? formik.errors.position : ''
              }
              name="position"
            />
          </div>
          <TextAreaField
            label="app.shared.whyJoinTheGarage-distrupt"
            name="reasonToJoin"
            isLongTitle={true}
            isValid={formik.touched.reasonToJoin && !formik.errors.reasonToJoin}
            value={formik.values.reasonToJoin != null ? formik.values.reasonToJoin : ''}
            errorMsg={
              formik.touched.reasonToJoin && formik.errors.reasonToJoin
                ? formik.errors.reasonToJoin
                : ''
            }
            limit={350}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
        </div>
      </GridView>
      <ReportProblemButtonComponent
        isLoading={isLoading}
        onClick={() => {
          formik.handleSubmit();
        }}
      />
      {succeSsfully && (
        <div
          className={`z-50 absolute h-full inset-0 ${
            i18n.language === 'en' ? 'left-80 mobile:left-0' : 'right-80 mobile:right-0'
          } mobile:top-0 mobile:left-0 tablet:top-80 shadow-2xl`}
        >
          <div className="flex justify-center mx-auto items-center h-full">
            <QRPopupComponent
              user_id={popupData?.id}
              date={popupData?.data}
              onClose={() => setSucceSsfully(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
