import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PopupTapsComponent from 'src/components/common/pop-up-taps-components';
import Tabs from 'src/components/user/CV/taps-component';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { CvInfo } from 'src/core/statics/cv-info.static';
import useProfileStore from 'src/core/stores/setUserData';
import { getCVService } from 'src/services/CV.service';

function CVInfoContainer() {
  const { i18n, t } = useTranslation();
  const [index, setIndex] = useState<number>(0);
  const [selectedName, setSelectedName] = useState<string>(CvInfo[0].name);
  const selectedCvInfoSections = CvInfo.find((h) => h.name === selectedName);
  const [selectedTab, setSelectedTab] = useState(0);
  const { setUserData } = useProfileStore();

  const handleSelectTab = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    const fetchCVData = async () => {
      try {
        const cvData = await getCVService();
        setUserData(cvData);
      } catch (error) {
        handleRequestError(error);
      }
    };

    fetchCVData();
  }, [setUserData]);

  return (
    <PopupTapsComponent
      index={index}
      taps={
        <div className="flex gap-4 mobile:gap-0">
          {CvInfo.map((items, index) => (
            <div
              key={index}
              onClick={() => {
                setIndex(index);
                setSelectedName(items.name);
              }}
              className={`
                flex justify-center rounded-t-2xl items-center text-center whitespace-nowrap py-2 px-4 mobile:w-full 
                tablet:w-28 w-[162px] h-10 cursor-pointer mobile:text-sm text-base laptop:w-auto
                ${i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'}
                ${
                  selectedName === items.name
                    ? 'text-primary bg-gray-400/5 rounded-t-2xl '
                    : 'text-white'
                }`}
            >
              {t(items.name)}
            </div>
          ))}
        </div>
      }
    >
      <div>
        {selectedCvInfoSections && (
          <Tabs
            isSubSection={selectedCvInfoSections.isSubSection}
            tabs={selectedCvInfoSections.Sections.map((section) => t(section.section))}
            selectedIndex={selectedTab}
            onSelectTab={handleSelectTab}
          >
            {selectedCvInfoSections.Sections.map((section) => section.content)}
          </Tabs>
        )}
      </div>
    </PopupTapsComponent>
  );
}

export default CVInfoContainer;
