import { useState } from 'react';
import EmptyStateCardComponent from 'src/components/user/V2/empty-state-card';
import useProfileStore from 'src/core/stores/setUserData';

import WorkExperienceFormContainer from './work-experience-form-container';
import WorkExperienceListContainer from './work-experience-list-container';

function WorkExperienceContainer() {
  const { data } = useProfileStore();
  const [selectedId, setSelectedId] = useState<string>();
  const [isExperience, setIsExperience] = useState(false);
  const experienceData = [...(data.experience || [])];
  const handleEdit = (id: string) => {
    setSelectedId(id);
    setIsExperience(true);
  };

  const handleAdd = () => {
    setSelectedId(undefined);
    setIsExperience(true);
  };

  return (
    <>
      {experienceData.length === 0 && !isExperience ? (
        <EmptyStateCardComponent
          title="container.WorkExperienceContainer.noWorkExperience"
          buttonLabel="container.WorkExperienceContainer.add"
          buttonOnClick={() => {
            setIsExperience(true);
            setSelectedId(undefined);
          }}
        />
      ) : isExperience ? (
        <WorkExperienceFormContainer setIsEditing={setIsExperience} selectedId={selectedId} />
      ) : (
        <WorkExperienceListContainer onEdit={handleEdit} onAdd={handleAdd} />
      )}
    </>
  );
}

export default WorkExperienceContainer;
