import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/ui/Button';
import GridView from 'src/components/common/ui/grid-view';
import { SizesEnum, VariantsEnum } from 'src/core/enums/ui.enums';

export default function EmptyStateCardComponent({
  title,
  buttonLabel,
  buttonOnClick,
  button1Label,
  button1OnClick,
}: {
  title: string;
  buttonLabel: string;
  buttonOnClick: () => void;
  button1Label?: string;
  button1OnClick?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex w-full items-center justify-center h-[51vh] pt-10 px-5 mobile:px-0">
      <div className="flex h-full w-full flex-col items-center justify-center rounded-2xl border border-dashed border-gray-600 ">
        <GridView column="1">
          <div className="text-center text-gray-300">{t(title)}</div>
          <div className="flex mobile:flex-col mobile:gap-3 justify-center mx-auto gap-x-3">
            <Button onClick={buttonOnClick} variant={VariantsEnum.Filled} size={SizesEnum.Small}>
              {t(buttonLabel)}
            </Button>
            {button1Label && button1OnClick && (
              <Button
                onClick={button1OnClick}
                variant={VariantsEnum.Outline}
                size={SizesEnum.Small}
              >
                {t(button1Label)}
              </Button>
            )}
          </div>
        </GridView>
      </div>
    </div>
  );
}
