export const gender_en = ['Male', 'Female'];
export const idType_en = ['Passport', 'National ID', 'Iqama'];

export const nationalities_en = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Antiguan',
  'Argentine',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bhutanese',
  'Bolivian',
  'Bosnian',
  'Brazilian',
  'British',
  'Bruneian',
  'Bulgarian',
  'Burkinabe',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Colombian',
  'Comorian',
  'Congolese',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cypriot',
  'Czech',
  'Danish',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorian',
  'Egyptian',
  'Emirati',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Eswatini',
  'Ethiopian',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Greek',
  'Grenadian',
  'Guatemalan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Honduran',
  'Hungarian',
  'Icelandic',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Italian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakh',
  'Kenyan',
  'Kuwaiti',
  'Kyrgyz',
  'Laotian',
  'Latvian',
  'Lebanese',
  'Lesotho',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithuanian',
  'Luxembourger',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Malian',
  'Maltese',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monacan',
  'Mongolian',
  'Montenegrin',
  'Moroccan',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Nicaraguan',
  'Nigerien',
  'Nigerian',
  'North Korean',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Philippine',
  'Polish',
  'Portuguese',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Lucian',
  'Salvadoran',
  'Samoan',
  'San Marinese',
  'Sao Tomean',
  'Saudi',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singaporean',
  'Slovak',
  'Slovenian',
  'Somali',
  'South African',
  'South Korean',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamese',
  'Swedish',
  'Swiss',
  'Syrian',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian',
  'Tunisian',
  'Turkish',
  'Turkmen',
  'Ugandan',
  'Ukrainian',
  'United States',
  'Uruguayan',
  'Uzbek',
  'Venezuelan',
  'Vietnamese',
  'Yemeni',
  'Zambian',
  'Zimbabwean',
];
export const countries_en = [
  'Saudi Arabia',
  'Bahrain',
  'Kuwait',
  'Oman',
  'Qatar',
  'United Arab Emirates',
  'Algeria',
  'Comoros',
  'Djibouti',
  'Egypt',
  'Iraq',
  'Jordan',
  'Lebanon',
  'Libya',
  'Mauritania',
  'Morocco',
  'State Of Palestine',
  'Somalia',
  'Sudan',
  'Syrian Arab Republic',
  'Tunisia',
  'Yemen',
  'Afghanistan',
  'Albania',
  'Azerbaijan',
  'Bangladesh',
  'Benin',
  'Brunei Darussalam',
  'Burkina Faso',
  'Cameroon',
  'Chad',
  'Ivory Coast',
  'Gambia',
  'Gabon',
  'Guinea',
  'Guinea Bissau',
  'Indonesia',
  'Iran',
  'Kazakhstan',
  'Kyrgyzstan',
  'Malaysia',
  'Maldives',
  'Mali',
  'Mozambique',
  'Niger',
  'Pakistan',
  'Senegal',
  'Sierra Leone',
  'Suriname',
  'Tajikistan',
  'Togo',
  'Turkey',
  'Turkmenistan',
  'Uganda',
  'Uzbekistan',
  'Armenia',
  'Bhutan',
  'British Indian Ocean Territory',
  'Cambodia',
  'China',
  'Cocos Keeling Islands',
  'Georgia',
  'Hong Kong',
  'India',
  'Japan',
  'North Korea',
  'South Korea',
  'Laos',
  'Macau',
  'Federated States Of Micronesia',
  'Mongolia',
  'Myanmar',
  'Nepal',
  'Singapore',
  'Sri Lanka',
  'Taiwan',
  'Thailand',
  'East Timor',
  'Vietnam',
  'Angola',
  'Botswana',
  'Burundi',
  'Cape Verde',
  'Central African Republic',
  'Congo',
  'Democratic Republic Of The Congo',
  'Equatorial Guinea',
  'Eritrea',
  'Ethiopia',
  'Ghana',
  'Kenya',
  'Lesotho',
  'Liberia',
  'Madagascar',
  'Malawi',
  'Mauritius',
  'Mayotte',
  'Namibia',
  'Réunion',
  'Rwanda',
  'Saint Helena',
  'Sao Tome And Principe',
  'Seychelles',
  'South Africa',
  'South Sudan',
  'Eswatini',
  'United Republic Of Tanzania',
  'Western Sahara',
  'Zambia',
  'Zimbabwe',
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'United Kingdom',
  'Åland Islands',
  'Andorra',
  'Belarus',
  'Faroe Islands',
  'Gibraltar',
  'Guernsey',
  'Holy See',
  'Iceland',
  'Isle Of Man',
  'Jersey',
  'Liechtenstein',
  'North Macedonia',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Norway',
  'Russian Federation',
  'San Marino',
  'Serbia',
  'Svalbard And Jan Mayen',
  'Switzerland',
  'Ukraine',
  'Anguilla',
  'Antigua And Barbuda',
  'Bahamas',
  'Barbados',
  'Belize',
  'Bermuda',
  'Canada',
  'Cayman Islands',
  'Costa Rica',
  'Cuba',
  'Dominica',
  'Dominican Republic',
  'El Salvador',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guatemala',
  'Haiti',
  'Honduras',
  'Jamaica',
  'Martinique',
  'Mexico',
  'Montserrat',
  'Nicaragua',
  'Panama',
  'Puerto Rico',
  'Saint Barthelemy',
  'Saint Kitts And Nevis',
  'Saint Martin French Part',
  'Saint Pierre And Miquelon',
  'Saint Vincent And The Grenadines',
  'Sint Maarten Dutch Part',
  'Trinidad And Tobago',
  'Turks And Caicos Islands',
  'United States Minor Outlying Islands',
  'United States Of America',
  'British Virgin Islands',
  'US Virgin Islands',
  'Argentina',
  'Aruba',
  'Bolivia',
  'Brazil',
  'Chile',
  'Colombia',
  'Curaçao',
  'Ecuador',
  'Falkland Islands',
  'French Guiana',
  'Guyana',
  'Paraguay',
  'Peru',
  'Uruguay',
  'Venezuela',
  'American Samoa',
  'Australia',
  'Christmas Island',
  'Cook Islands',
  'Fiji',
  'French Polynesia',
  'French Southern Territories',
  'Guam',
  'Kiribati',
  'Marshall Islands',
  'Nauru',
  'New Caledonia',
  'Niue',
  'Palau',
  'Papua New Guinea',
  'Pitcairn Islands',
  'Samoa',
  'Solomon Islands',
  'Tokelau',
  'Tonga',
  'Tuvalu',
  'Vanuatu',
  'Wallis And Futuna',
];
