import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import LinkInputComponent from 'src/components/user/V2/link-input-compoent';
import ProfileEditComponent from 'src/components/user/V2/profile-eddit-component';
import SaveCancelButton from 'src/components/user/V2/save-cancel-button';
import { refreshTokenService } from 'src/core/data-access/token.service';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { ProfileFormDataType } from 'src/core/types/profile-form-type';
import { getProfile, updateProfile } from 'src/services/profile.service';

import { initialValues } from './profile-initialValues';
import { validationSchema } from './profile-validationSchema';

export default function ProfileFormContainer() {
  const [eddit, setEdit] = useState(false);
  const [profileData, setProfileData] = useState<ProfileFormDataType>();
  const navigate = useNavigate();

  const fetchProfile = async () => {
    const response = await getProfile();
    setProfileData(response);
  };

  const handleSubmit = async (values: ProfileFormDataType) => {
    const isValid = await validationSchema.isValid(values, { abortEarly: false });
    try {
      const changedValues: Partial<ProfileFormDataType> = {};
      const originalValues = profileData || initialValues;

      Object.keys(values).forEach((key) => {
        const k = key as keyof ProfileFormDataType;
        if (values[k] !== originalValues[k]) {
          changedValues[k] = values[k];
        }
      });

      if (Object.keys(changedValues).length > 0) {
        if (changedValues.email) {
          try {
            if (isValid) {
              await updateProfile(values);
              handleRequestError.success('container.OTPFormContainer.success');
              navigate(appRoutesObj.getOTPVerificationPagePath(), {
                state: { profileData: values },
              });
            }
          } catch (error) {
            setEdit(true);
            handleRequestError(error);
          }
          return;
        }
        await updateProfile(values);
        const response = await getProfile();
        refreshTokenService(localStorage.getItem('refreshToken') as string);
        handleRequestError.success('container.CV.confirmation.updated');
        setProfileData(response);
      }
      setEdit(false);
    } catch (error) {
      handleRequestError(error);
    }
  };
  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Formik
      initialValues={profileData || initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, handleChange, errors, touched, resetForm }) => (
        <Form className="flex flex-col gap-y-4 px-7 h-full">
          <ProfileEditComponent
            title="app.shared.personalInformation"
            isEdit={eddit}
            onEditClick={() => {
              setEdit(!eddit);
            }}
          />
          <GridView column="1">
            <InputField
              label="app.shared.name"
              disabled={!eddit}
              name="name"
              isValid={touched.name && !errors.name}
              value={values.name ?? ''}
              onChange={handleChange}
              errorMsg={errors.name}
            />
            <InputField
              label="profile.input.username"
              disabled={!eddit}
              name="username"
              isValid={touched.username && !errors.username}
              value={values.username ?? ''}
              onChange={handleChange}
              errorMsg={errors.username}
            />
            <InputField
              label="app.shared.email"
              disabled={!eddit}
              name="email"
              isValid={touched.email && !errors.email}
              value={values.email ?? ''}
              onChange={handleChange}
              errorMsg={errors.email}
            />
            <InputField
              label="app.shareed.phone"
              disabled={!eddit}
              name="mobile"
              isValid={touched.mobile && !errors.mobile}
              value={values.mobile ?? ''}
              onChange={handleChange}
              errorMsg={errors.mobile}
            />
            <LinkInputComponent
              title="profile.input.password"
              link="profile.input.changePassword"
              onClick={() => {
                navigate(appRoutesObj.getChangePasswordPagePath(), {
                  state: { profileData: values },
                });
              }}
            />
          </GridView>
          {eddit && (
            <SaveCancelButton
              onCancel={() => {
                resetForm();
                setEdit(false);
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}
