import { useState } from 'react';
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BiCalendar, BiTimeFive } from 'react-icons/bi';
import { GrLocation } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import TitleComponent from 'src/components/common/title-component';
import { Button } from 'src/components/common/ui/Button';
import Tabs from 'src/components/common/ui/Taps';
import CardBorderGreenComponent from 'src/components/user/card-border-green-component';
import { ColorsEnum, VariantsEnum } from 'src/core/enums/ui.enums';
import { opengraphMetaTags } from 'src/core/helpers/example.helper';
import { getDemoDayInNumbersData } from 'src/core/statics/garage-plus.static';

function GaragePlusContainer() {
  const { t, i18n } = useTranslation();
  const [subPage, setSubPage] = useState<number>(0);
  const isFirstTab = subPage === 0;
  const isEnglish = i18n.language === 'en';

  return (
    <>
      <Helmet
        title={`${t('app.shared.theGarage')} | ${t('container.GaragePlusContainer.plusOverView')}`}
        meta={[
          ...opengraphMetaTags({
            title: `${t('app.shared.theGarage')} | ${t(
              'container.GaragePlusContainer.plusOverView'
            )}`,
            description: `${t('component.user.SidebarComponent.theGarageDescreption')}`,
            logo: '/assets/images/logo.png',
          }),
          {
            name: 'description',
            content: `${t('component.user.SidebarComponent.theGarageDescreption')}`,
          },
        ]}
      />
      <div className="flex flex-col gap-6 px-2">
        <div className="mobile:text-center tablet:text-center text-start mb-8">
          <h1 className="text-white text-base font-bold tracking-wider">THE GARAGE</h1>
          <h2 className="text-secondary text-xs leading-[5px] font-bold">DEMO DAY</h2>
        </div>
        <div className="flex">
          <div
            className={`flex rounded-lg ${
              isFirstTab
                ? `bg-gradient-to-${isEnglish ? 'r' : 'l'} from-secondary/20`
                : 'text-neutral-400'
            }`}
          >
            <Tabs
              title="containers.GaragePlusContainer.aboutEvent"
              onSelect={() => setSubPage(0)}
            />
          </div>
        </div>

        {isFirstTab && (
          <div className="mobile:h-screen">
            <div className="flex justify-between gap-8 mobile:flex-col tablet:flex-col">
              <div className="grid w-[60%] items-start justify-start mobile:w-full gap-4">
                <div className="flex flex-col items-start justify-center mx-auto gap-4">
                  <p
                    className={`${
                      isEnglish ? ' font-helveticaMedium text-base' : 'font-arMyriad text-xl'
                    } text-neutral-400 text-justify h-auto w-full px-2`}
                  >
                    {t('container.GaragePlusContainer.plusOverView')}
                  </p>
                  <div className="flex flex-col justify-center pt-2 items-start gap-3">
                    <CardBorderGreenComponent
                      title={t('component.plusTimeAndDateComponent.date')}
                      icon={BiCalendar}
                    />
                    <CardBorderGreenComponent title={t('app.shared.tiem.7pm')} icon={BiTimeFive} />
                    <CardBorderGreenComponent
                      title={t('component.plusTimeAndDateComponent.location')}
                      icon={GrLocation}
                    />
                  </div>
                  <div className="flex flex-col mobile:items-center mobile:w-full">
                    <Link to={appRoutesObj.getGaragePlusFormPageUrl()}>
                      <Button
                        type="submit"
                        color={ColorsEnum.Primary}
                        variant={VariantsEnum.Filled}
                        className={`${
                          isEnglish
                            ? 'px-5 font-helveticaBold text-xs'
                            : 'px-7 font-arMyriadBold text-base'
                        } mobile:px-12 mobile:w-1/2 mobile:py-3 py-2 `}
                      >
                        {t('app.GaragePlusContainer.button.registerNow')}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="w-[45%] largeLaptop:w-[40%] tablet:w-full mobile:w-full mobile:pt-7">
                <TitleComponent
                  color="text-white"
                  title="container.GaragePlusContainer.demoDayInNumbers"
                />
                <div className="grid grid-cols-2 pt-4 gap-4 w-full h-auto">
                  {getDemoDayInNumbersData.map((val, index) => (
                    <div className="py-0 bg-black/20 rounded-2xl" key={index}>
                      <p className="text-2xl font-helveticaBold text-center text-secondary">
                        <CountUp
                          className="text-[23px] font-helveticaBold text-center text-secondary"
                          end={Number(val.Number)}
                          duration={2}
                          useGrouping={false}
                        />
                      </p>
                      <p
                        className={`${
                          isEnglish
                            ? 'font-helveticaMedium text-sm laptop:text-xs'
                            : 'font-arMyriad text-base leading-5 laptop:text-sm'
                        } py-1 pt-2 text-center text-neutral-400`}
                      >
                        {t(val.label)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GaragePlusContainer;
