import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/ui/Button';
import SelectComponent2 from 'src/components/common/ui/CV-select-component';
import { optionsList } from 'src/components/common/ui/CV-select-component';
import InputField from 'src/components/common/ui/InputField';
import ProfileEditComponent from 'src/components/user/V2/profile-eddit-component';
import SaveCancelButton from 'src/components/user/V2/save-cance-button';
import { Country } from 'src/core/enums/profile.enum';
import { VariantsEnum } from 'src/core/enums/ui.enums';
import { SizesEnum } from 'src/core/enums/ui.enums';
import { ColorsEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { CVType } from 'src/core/types/CV.type';
import { putCVService } from 'src/services/CV.service';

import { validationSchema } from './contact-info-form.validation';
import { cvInitialValues } from '../initialValues';

function ContactInfoContainer() {
  const countryOptions: optionsList[] = Object.entries(Country).map(([key, value]) => ({
    value: value,
    key: key,
  }));

  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const { data, setUserData } = useProfileStore();

  const handleSubmit = async (values: CVType) => {
    try {
      await putCVService({ ...values, email: values.email?.length == 0 ? null : values.email });
      setUserData({ ...data, ...values });
      setIsEditing(false);
      handleRequestError.success(t('container.CV.confirmation.saved'));
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleEdit = () => setIsEditing(true);

  return (
    <Formik
      initialValues={{ ...cvInitialValues, ...data }}
      validationSchema={validationSchema()}
      onSubmit={(values) => handleSubmit(values)}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, errors, touched, resetForm }) => (
        <Form>
          <div className="flex flex-col gap-6">
            {!isEditing && (
              <div className="mobile:hidden tablet:hidden">
                <ProfileEditComponent
                  isEdit={isEditing}
                  onEditClick={() => {
                    setIsEditing(!isEditing);
                  }}
                />
              </div>
            )}
            <InputField
              label="app.shared.email"
              name="email"
              isValid={touched.email && !errors.email}
              value={values.email ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditing}
              errorMsg={touched.email ? errors.email : ''}
            />
            <InputField
              label="app.shareed.phone"
              name="mobile"
              type="tel"
              inputMode="numeric"
              value={values.mobile ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={touched.mobile ? errors.mobile : ''}
              isValid={touched.mobile && !errors.mobile}
              disabled={!isEditing}
            />
            <SelectComponent2
              name="country"
              selectLabel="app.shared.country"
              options={countryOptions}
              value={values.country ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={touched.country ? errors.country : ''}
              isValid={touched.country && !errors.country}
              disabled={!isEditing}
            />

            {!isEditing && (
              <div className="flex justify-center w-full desktop:hidden largeLaptop:hidden laptop:hidden items-center mx-auto gap-2">
                <Button
                  variant={VariantsEnum.Filled}
                  color={ColorsEnum.Primary}
                  size={SizesEnum.Medium}
                  onClick={handleEdit}
                >
                  {t('app.shared.edit')}
                </Button>
              </div>
            )}
            {isEditing && (
              <SaveCancelButton
                onCancel={() => {
                  resetForm();
                  setIsEditing(false);
                }}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ContactInfoContainer;
