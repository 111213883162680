/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import Pagination from 'src/components/common/Pagination/pagination';
import { NewsTypesEnum } from 'src/core/enums/service.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { GarageNewsList } from 'src/core/types/user.type';
import { getNewsListService } from 'src/services/news.service';

import NewsSkeletonContainer from '../skeleton/news-skeleton-container';

function NewsContainer() {
  const { t, i18n } = useTranslation();
  const [news, setNews] = useState<GarageNewsList>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const paginationLimit = 6;
  const [page, setPage] = useState<number>(1);

  const getAllGarageNews = useCallback(async () => {
    setIsLoading(true);
    try {
      const newsLater = await getNewsListService(
        i18n.language.toUpperCase(),
        NewsTypesEnum.NEWS_LETTER,
        (page - 1) * paginationLimit,
        paginationLimit
      );
      setIsLoading(false);
      setNews(newsLater);
    } catch (error) {
      handleRequestError(error);
    }
  }, [i18n.language, page]);

  useEffect(() => {
    getAllGarageNews();
  }, [getAllGarageNews]);

  return (
    <div className="flex flex-col h-full pb-2">
      {isLoading ? (
        <NewsSkeletonContainer count={6} />
      ) : (
        <div className="grid grid-cols-3 grid-rows-2 relative gap-2 mobile:gap-4 px-2 pt-4 tablet:grid-cols-1 mobile:grid-cols-1 tablet:px-40">
          {news?.result_list.map((item, index) => (
            <div
              key={index}
              className="rounded-2xl p-3 border h-56 largeLaptop:h-[180px] laptop:h-[150px] border-gray-600/50 bg-neutral-500/10 mobile:bg-inherit"
            >
              <div className="flex flex-col justify-center">
                <img
                  className="h-[120px] largeLaptop:h-[90px] laptop:h-20 w-full object-cover rounded-xl"
                  src={item.url}
                  alt="logo"
                />

                <p
                  className={`pt-3 text-white ${
                    i18n.language === 'en'
                      ? 'text-sm font-helveticaMedium largeLaptop:text-xs laptop:text-xs'
                      : 'text-base font-arMyriad'
                  }`}
                >
                  {t(`${item.title}`)}
                </p>
                <Link to={appRoutesObj.getNewsDetailsPageUrl(news.result_list[index].id || '')}>
                  <p
                    className={`cursor-pointer absolute text-primary underline bottom-4 text-xs 
                ${
                  i18n.language === 'en'
                    ? 'font-helveticaMedium right-4 text-right'
                    : 'font-arMyriad left-4 text-left'
                }`}
                  >
                    {t('app.shared.knowMore')}
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="px-3 flex justify-start h-full items-end mobile:justify-center tablet:justify-center">
        <Pagination
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          totalPage={Math.ceil(news?.total_count! / paginationLimit)}
          page={page}
          limit={paginationLimit}
          onPageChange={setPage}
          totalCount={news?.total_count || 0}
        />
      </div>
    </div>
  );
}

export default NewsContainer;
