/* eslint-disable import/named */
import { FormikTouched, FormikValues, FormikErrors, useFormik, Formik } from 'formik';
import { useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Popup from 'src/components/common/pop-up';
import ProgramSteps from 'src/components/common/program-steps';
import { SharedButtonComponent } from 'src/components/common/shared-button-component';
import DateField from 'src/components/common/ui/DateField2';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import TextAreaField from 'src/components/common/ui/textArea';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { PermitType } from 'src/core/types/user.type';
import { postPermitService } from 'src/services/permit.service';

import { initialValues } from './permit.data';
import { validationSchema } from './permit.validation';

export default function PermitContainer() {
  const sliderRef = useRef<HTMLDivElement>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [activeIndex, setActiveIndex] = useState(0);
  const [successfully, setSuccessfully] = useState<boolean>(false);
  const [isvalid, setIsvalid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fieldsToCheck: string[] = ['name', 'email', 'mobile', 'vehicle', 'nationality'];
  const fieldsToCheck2: string[] = ['equipment', 'quantity', 'description'];
  const content = [
    { label: 'app.shared.personalInformation', color: '#737373' },
    { label: 'permit.step.MaterialDescription', color: '#737373' },
    { label: 'app.shared.TransportationDetails', color: '#737373' },
  ];
  const isLastSection = activeIndex === content.length - 1;
  const handleSubmit = async () => {
    setIsLoading(!isLoading);
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        await postPermitService({ ...formik.values }, token);
        setSuccessfully(!successfully);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };
  const handleBackClick = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + content.length) % content.length);
  };
  const scrollToTop = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  const handleNext = (
    fieldsToCheck: string[],
    formik: {
      setTouched: (touched: FormikTouched<PermitType>) => void;
      values: FormikValues;
      errors: FormikErrors<PermitType>;
    },
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any[]
  ) => {
    const newTouched: FormikTouched<PermitType> = {};

    fieldsToCheck.forEach((field) => {
      if (!formik.values[field]) {
        newTouched[field] = true;
      }
    });
    const currentStep = activeIndex;
    let fieldsToValidate: string[];

    if (currentStep === 0) {
      fieldsToValidate = fieldsToCheck;
    } else if (currentStep === 1) {
      fieldsToValidate = fieldsToCheck2;
    } else {
      return handleSubmit();
    }

    fieldsToValidate.forEach((field) => {
      newTouched[field] = true;
    });

    formik.setTouched(newTouched);

    const hasErrors = fieldsToValidate.some(
      (field) => !formik.values[field] || !!formik.errors[field]
    );

    if (!hasErrors) {
      setIsvalid(!isvalid);
      setActiveIndex((prevIndex) => Math.min(prevIndex + 1, content.length - 1));
      scrollToTop();
    }
  };

  const handlClick = () => {
    if (activeIndex !== 0 && activeIndex !== 1) {
      handleBackClick();
    } else if (activeIndex === 0) {
      handleNext(fieldsToCheck, formik, setActiveIndex, content);
    } else if (activeIndex === 1) {
      handleNext(fieldsToCheck2, formik, setActiveIndex, content);
    } else {
      handleSubmit();
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: validationSchema(),
  });

  return (
    <div className="h-auto largeLaptop:h-auto overflow-y-auto px-[11%] mobile:px-4 tablet:px-4">
      <div
        className={`flex gap-48 justify-center mobile:justify-between tablet:justify-between laptop:gap-3 mobile:px-0 mobile:${
          isLastSection ? 'gap-2.5' : 'gap-4'
        } tablet:gap-4 text-base text-neutral-500`}
      >
        {content.map((item, index) => (
          <ProgramSteps
            activeIndex={activeIndex}
            index={index}
            completed={activeIndex > index}
            color={item.color}
            label={item.label}
            key={index}
            onClick={handlClick}
          />
        ))}
      </div>
      <div
        ref={sliderRef}
        className="py-2 h-[310px] largeLaptop:h-[240px] laptop:h-[170px] mobile:h-[400px] tablet:h-[360px] overflow-y-auto mobile:mb-2"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={() => formik.submitForm()}
          validationSchema={formik.initialValues}
          validateOnChange
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {activeIndex === 0 && (
              <GridView column={'2'}>
                <InputField
                  label="app.shared.driverName"
                  name="name"
                  isValid={formik.touched.name && !formik.errors.name}
                  value={formik.values.name != null ? formik.values.name : ''}
                  errorMsg={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                  onChange={(e) => {
                    formik.setFieldValue('name', e.target.value);
                    formik.handleChange(e);
                  }}
                />

                <InputField
                  label="app.shared.mobile.number"
                  name="mobile"
                  isValid={formik.touched.mobile && !formik.errors.mobile}
                  value={formik.values.mobile != null ? formik.values.mobile : ''}
                  errorMsg={
                    formik.touched.mobile && formik.errors.mobile ? formik.errors.mobile : ''
                  }
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '');

                    formik.setFieldValue('mobile', numericValue);
                  }}
                />
                <InputField
                  label="app.shared.email.requierd"
                  name="email"
                  isValid={formik.touched.email && !formik.errors.email}
                  value={formik.values.email != null ? formik.values.email : ''}
                  errorMsg={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                  onChange={(e) => {
                    formik.setFieldValue('email', e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="app.shared.nationalty"
                  name="nationality"
                  isValid={formik.touched.nationality && !formik.errors.nationality}
                  value={formik.values.nationality != null ? formik.values.nationality : ''}
                  errorMsg={
                    formik.touched.nationality && formik.errors.nationality
                      ? formik.errors.nationality
                      : ''
                  }
                  onChange={(e) => {
                    formik.setFieldValue('nationality', e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <div className="col-span-2 mobile:col-span-1 tablet:col-span-1">
                  <InputField
                    label="form.lapel.Vehicle"
                    name="vehicle"
                    isValid={formik.touched.vehicle && !formik.errors.vehicle}
                    value={formik.values.vehicle != null ? formik.values.vehicle : ''}
                    errorMsg={
                      formik.touched.vehicle && formik.errors.vehicle ? formik.errors.vehicle : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('vehicle', e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                </div>
              </GridView>
            )}
            {activeIndex === 1 && (
              <GridView column={'2'}>
                <InputField
                  label="form.lapel.Equipment"
                  name="equipment"
                  isValid={formik.touched.equipment && !formik.errors.equipment}
                  value={formik.values.equipment != null ? formik.values.equipment : ''}
                  errorMsg={
                    formik.touched.equipment && formik.errors.equipment
                      ? formik.errors.equipment
                      : ''
                  }
                  onChange={(e) => {
                    formik.setFieldValue('equipment', e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="form.lapel.Quantity"
                  name="quantity"
                  type="number"
                  isValid={formik.touched.quantity && !formik.errors.quantity}
                  value={formik.values.quantity != null ? formik.values.quantity : ''}
                  errorMsg={
                    formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : ''
                  }
                  onKeyPress={(event) => {
                    if (!/[0-9]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '');

                    formik.setFieldValue('quantity', numericValue);
                  }}
                />

                <InputField
                  label="app.shared.description"
                  name="description"
                  isValid={formik.touched.description && !formik.errors.description}
                  value={formik.values.description != null ? formik.values.description : ''}
                  errorMsg={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : ''
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="form.lapel.Notes"
                  name="notes"
                  isValid={formik.touched.notes && !formik.errors.notes}
                  value={formik.values.notes != null ? formik.values.notes : ''}
                  errorMsg={formik.touched.notes && formik.errors.notes ? formik.errors.notes : ''}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </GridView>
            )}

            {activeIndex == 2 && (
              <GridView column="1">
                <DateField
                  label="form.lapel.Date"
                  name="permit_date"
                  value={formik.values.permit_date || ''} // Pass date as DD/MM/YYYY formatted string
                  isValid={formik.touched.permit_date && !formik.errors.permit_date}
                  errorMsg={
                    formik.touched.permit_date && formik.errors.permit_date
                      ? formik.errors.permit_date
                      : ''
                  }
                  onChange={(e) => {
                    formik.setFieldValue('permit_date', e.target.value); // e.target.value is in DD/MM/YYYY format
                  }}
                />

                <TextAreaField
                  label="form.lapel.Reason"
                  name="reason"
                  isValid={formik.touched.reason && !formik.errors.reason}
                  value={formik.values.reason != null ? formik.values.reason : ''}
                  errorMsg={
                    formik.touched.reason && formik.errors.reason ? formik.errors.reason : ''
                  }
                  limit={250}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
                <GridView column={'2'}>
                  <InputField
                    label="form.lapel.Sender"
                    name="transfer_from"
                    isValid={formik.touched.transfer_from && !formik.errors.transfer_from}
                    value={formik.values.transfer_from != null ? formik.values.transfer_from : ''}
                    errorMsg={
                      formik.touched.transfer_from && formik.errors.transfer_from
                        ? formik.errors.transfer_from
                        : ''
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  <InputField
                    label="form.lapel.Receiver"
                    name="transfer_to"
                    isValid={formik.touched.transfer_to && !formik.errors.transfer_to}
                    value={formik.values.transfer_to != null ? formik.values.transfer_to : ''}
                    errorMsg={
                      formik.touched.transfer_to && formik.errors.transfer_to
                        ? formik.errors.transfer_to
                        : ''
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                </GridView>
                <GridView column={'2'}>
                  <InputField
                    label="form.lapel.referName"
                    name="refer_name"
                    isValid={formik.touched.refer_name && !formik.errors.refer_name}
                    value={formik.values.refer_name != null ? formik.values.refer_name : ''}
                    errorMsg={
                      formik.touched.refer_name && formik.errors.refer_name
                        ? formik.errors.refer_name
                        : ''
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  <InputField
                    label="form.lapel.referMobile"
                    name="refer_mobile"
                    isValid={formik.touched.refer_mobile && !formik.errors.refer_mobile}
                    value={formik.values.refer_mobile != null ? formik.values.refer_mobile : ''}
                    errorMsg={
                      formik.touched.refer_mobile && formik.errors.refer_mobile
                        ? formik.errors.refer_mobile
                        : ''
                    }
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, '');

                      formik.setFieldValue('refer_mobile', numericValue);
                    }}
                  />
                </GridView>
              </GridView>
            )}
          </form>
        </Formik>
      </div>

      <SharedButtonComponent
        isLoading={isLoading}
        isLastSection={isLastSection}
        activeIndex={activeIndex}
        handleBackClick={handleBackClick}
        onClick={() => {
          if (activeIndex === 0) {
            handleNext(fieldsToCheck, formik, setActiveIndex, content);
          } else if (activeIndex === 1) {
            handleNext(fieldsToCheck2, formik, setActiveIndex, content);
          }
          if (isLastSection) {
            formik.handleSubmit();
          }
        }}
      />
      {successfully && (
        <div className={`z-50 absolute h-full inset-0`}>
          <div className="flex justify-center mx-auto items-center h-full">
            <Popup
              body="popup-successfully"
              onClose={() => {
                setSuccessfully(false);
                formik.resetForm();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
