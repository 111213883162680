import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import Booking from 'src/containers/user/Booking';
import Community from 'src/containers/user/community';
import Programs from 'src/containers/user/landing/programs-container';

import MobileBookingPage from '../mobile-booking-page';
import MobileCommunityPage from '../mobile-community-page';
import MobilePrograms from '../mobile-programs';
import MobileThegaragePage from '../mobile-thegarage-page';

function LandingPage() {
  const { t, i18n } = useTranslation();
  const [isActive, setIsActive] = useState<number>(0);
  const navigate = useNavigate();
  const NavItems = [
    {
      id: 0,
      label: 'app.shared.theGarage',
    },
    {
      id: 1,
      label: 'containers.user.landing.programsTitel',
    },
    {
      id: 2,
      label: 'app.shared.community',
    },
    {
      id: 3,
      label: 'container.user.Bookings.title',
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language') || 'ar');
  }, [i18n]);

  return (
    <Layout>
      <>
        <div
          className={`grid mobile:hidden tablet:hidden   ${
            i18n.language === 'en' ? 'gap-y-[2%] laptop:gap-y-1' : 'gap-y-[4%] laptop:gap-y-0'
          } mobile:gap-y-5 tablet:gap-y-7 w-full`}
        >
          <Programs />
          <Community />
          <Booking />
        </div>
        <div className="h-full">
          <div className="w-full h-[75dvh] justify-between flex flex-col desktop:hidden laptop:hidden largeLaptop:hidden">
            {/* Main Scrollable Area */}

            <div className="flex flex-col gap-10 pt-12 px-5 ">
              <div>
                {isActive === 0 && <MobileThegaragePage />}
                {isActive === 1 && <MobilePrograms />}
                {isActive === 2 && <MobileCommunityPage />}
                {isActive === 3 && <MobileBookingPage />}
              </div>
            </div>

            {/* Sticky Navigation Bar */}
            <div className="fixed bottom-0 w-full">
              <div className="flex items-end justify-end gap-x-0 py-6 px-6 bg-neutral-700/50 rounded-t-lg w-full ">
                {NavItems.map((value, index) => (
                  <div key={index} className="w-full justify-between">
                    <div
                      className="flex flex-col justify-center mx-auto items-center"
                      onClick={() => (setIsActive(index), navigate(appRoutesObj.getBasePath()))}
                    >
                      <nav
                        className={`text-white z-50 relative w-full h-full whitespace-nowrap flex flex-col items-center gap-x-2 justify-start ${
                          i18n.language === 'en'
                            ? 'text-sm font-helveticaMedium'
                            : 'text-lg font-arMyriad'
                        }`}
                      >
                        <div className="z-50">{t(value.label)}</div>

                        {index === isActive && (
                          <img
                            src="/assets/images/nav-backgroud.png"
                            alt=""
                            className="absolute left-0 -bottom-12 w-full h-28 z-10 tablet:left-12"
                          />
                        )}
                      </nav>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
}

export default LandingPage;
