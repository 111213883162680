import {
  BiBookReader,
  BiBriefcase,
  BiChalkboard,
  BiMapPin,
  BiNetworkChart,
  BiTerminal,
} from 'react-icons/bi';
import { BsBuildings, BsGraphUpArrow, BsGraphUp, BsBarChartLine } from 'react-icons/bs';
import {
  FaChalkboardTeacher,
  FaChartLine,
  FaMapMarkerAlt,
  FaNetworkWired,
  FaSeedling,
  FaUsers,
} from 'react-icons/fa';
import { FiAward } from 'react-icons/fi';
import { GoLightBulb } from 'react-icons/go';
import { GrSteps, GrUserManager } from 'react-icons/gr';
import { HiOutlinePresentationChartBar, HiOutlineServer } from 'react-icons/hi';
import { ImMagicWand } from 'react-icons/im';
import { MdBiotech, MdOutlineChair, MdOutlineWorkspaces } from 'react-icons/md';
import {
  RiBookmark3Line,
  RiEarthLine,
  RiFlashlightLine,
  RiPagesLine,
  RiTeamLine,
} from 'react-icons/ri';

export interface InternalPrograms {
  name: string;
  programActiveImg: string;
  aboutProgram: string;
  programStage: ProgramStage;
  targetSectors?: TargetSectors[];
  selectionCriteria: SelectionCriteria[];
  whatMakeUsSpecial: WhatMakeUsSpecial[];
}
export interface ProgramStage {
  imgEn: string;
  imgAr: string;
}
interface TargetSectors {
  text: string;
}
interface SelectionCriteria {
  id: number;
  title: string;
  description: string;
  icon: JSX.Element;
}
interface WhatMakeUsSpecial {
  icon: JSX.Element;
  label: string;
  description: string;
}

export const internalPrograms: InternalPrograms[] = [
  {
    name: 'Plus',
    programActiveImg: 'plus',
    aboutProgram: 'containers.InternalProgramsContainer.aboutPlus',
    programStage: { imgAr: 'plus-ar', imgEn: 'plus-en' },

    selectionCriteria: [
      {
        id: 1,
        title: 'containers.InternalProgramsContainer.plusSelectionCriteria1.title1',
        icon: <FaUsers />,
        description: '',
      },
      {
        id: 2,
        title: 'containers.InternalProgramsContainer.plusSelectionCriteria2.title2',
        icon: <GrSteps />,
        description: '',
      },
      {
        id: 3,
        title: 'containers.InternalProgramsContainer.plusSelectionCriteria3.title3',
        icon: <FaChartLine />,
        description: '',
      },
      {
        id: 4,
        title: 'containers.InternalProgramsContainer.plusSelectionCriteria4.title4',
        icon: <BsGraphUpArrow />,
        description: '',
      },
      {
        id: 5,
        title: 'containers.InternalProgramsContainer.plusSelectionCriteria5.title5',
        icon: <RiTeamLine />,
        description: '',
      },
      {
        id: 6,
        title: 'containers.InternalProgramsContainer.plusSelectionCriteria6.title6',
        icon: <FaSeedling />,
        description: '',
      },
      {
        id: 7,
        title: 'containers.InternalProgramsContainer.plusSelectionCriteria7.title7',
        icon: <FaMapMarkerAlt />,
        description: '',
      },
    ],
    whatMakeUsSpecial: [
      {
        label: 'app.shared.community',
        description: '',
        icon: <FaUsers size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.plusMakesUsSpecial2',
        description: '',
        icon: <FaChalkboardTeacher size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.plusMakesUsSpecial3',
        description: '',
        icon: <MdOutlineWorkspaces size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.plusMakesUsSpecial4',
        description: '',
        icon: <FaNetworkWired size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.plusMakesUsSpecial5',
        description: '',
        icon: <HiOutlinePresentationChartBar size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.plusMakesUsSpecial6',
        description: '',
        icon: <RiFlashlightLine size={24} />,
      },
    ],
  },
  {
    name: 'Incubator',
    programActiveImg: 'incubator',
    aboutProgram: 'containers.InternalProgramsContainer.aboutIncubator',
    programStage: { imgAr: 'incubator-ar', imgEn: 'incubator-en' },
    targetSectors: [
      { text: 'containers.InternalProgramsContainer.incubatorTargetSector1' },
      { text: 'containers.InternalProgramsContainer.incubatorTargetSector5' },
    ],
    selectionCriteria: [
      {
        id: 1,
        title: 'containers.InternalProgramsContainer.incubatorSelectionCriteria1.title1',
        icon: <BsBuildings />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria1.subTitle1',
      },
      {
        id: 2,
        title: 'app.shared.productLevel',
        icon: <GrSteps />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria2.subTitle2',
      },
      {
        id: 3,
        title: 'app.shared.techSloution',
        icon: <GoLightBulb />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria3.subTitle3',
      },
      {
        id: 4,
        title: 'containers.InternalProgramsContainer.incubatorSelectionCriteria4.title4',
        icon: <RiTeamLine />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria4.subTitle4',
      },
      {
        id: 5,
        title: 'containers.InternalProgramsContainer.incubatorSelectionCriteria5.title5',
        icon: <RiPagesLine />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria5.subTitle5',
      },
      {
        id: 6,
        title: 'app.shared.availbalty',
        icon: <RiBookmark3Line />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria6.subTitle6',
      },
      {
        id: 7,
        title: 'containers.InternalProgramsContainer.incubatorSelectionCriteria7.title7',
        icon: <GrUserManager />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria7.subTitle7',
      },
      {
        id: 8,
        title: 'containers.InternalProgramsContainer.incubatorSelectionCriteria8.title8',
        icon: <BiMapPin />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria8.subTitle8',
      },
      {
        id: 9,
        title: 'app.shared.expansion',
        icon: <RiEarthLine />,
        description: 'containers.InternalProgramsContainer.incubatorSelectionCriteria9.subTitle9',
      },
    ],
    whatMakeUsSpecial: [
      {
        label: 'containers.InternalProgramsContainer.incubatorMakesUsSpecial1',
        description: '',
        icon: <BiChalkboard size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.incubatorMakesUsSpecial2',
        description: '',
        icon: <HiOutlineServer size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.incubatorMakesUsSpecial3',
        description: '',
        icon: <MdOutlineChair size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.incubatorMakesUsSpecial4',
        description: '',
        icon: <BsBarChartLine size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.incubatorMakesUsSpecial5',
        description: '',
        icon: <BiTerminal size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.incubatorMakesUsSpecial6',
        description: '',
        icon: <MdBiotech size={24} />,
      },
    ],
  },
  {
    name: 'Ignition',
    programActiveImg: 'Ignition',
    aboutProgram: 'containers.InternalProgramsContainer.aboutIgnition',
    programStage: { imgAr: 'ignition-ar', imgEn: 'ignition-en' },
    targetSectors: [
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector1' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector2' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector3' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector4' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector5' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector6' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector7' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector8' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector9' },
      { text: 'containers.InternalProgramsContainer.IgnitionTargetSector10' },
    ],
    selectionCriteria: [
      {
        id: 1,
        title: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria1.title1',
        icon: <BsBuildings />,
        description: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria1.subTitle1',
      },
      {
        id: 2,
        title: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria2.title2',
        icon: <RiTeamLine />,
        description: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria2.subTitle2',
      },
      {
        id: 3,
        title: 'app.shared.productLevel',
        icon: <GrSteps />,
        description: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria3.subTitle3',
      },
      {
        id: 4,
        title: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria4.title4',
        icon: <BsGraphUp />,
        description: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria4.subTitle4',
      },
      {
        id: 5,
        title: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria5.title5',
        icon: <FiAward />,
        description: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria5.subTitle5',
      },
      {
        id: 6,
        title: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria6.title6',
        icon: <GrUserManager />,
        description: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria6.subTitle6',
      },
      {
        id: 7,
        title: 'app.shared.expansion',
        icon: <RiEarthLine />,
        description: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria7.subTitle7',
      },
      {
        id: 8,
        title: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria8.title8',
        icon: <RiBookmark3Line />,
        description: 'containers.InternalProgramsContainer.IgnitionSelectionCriteria8.subTitle8',
      },
    ],
    whatMakeUsSpecial: [
      {
        label: 'containers.InternalProgramsContainer.IgnitionMakesUsSpecial1',
        description: '',
        icon: <ImMagicWand size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.IgnitionMakesUsSpecial2',
        description: '',
        icon: <BiBookReader size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.IgnitionMakesUsSpecial3',
        description: '',
        icon: <BiBriefcase size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.IgnitionMakesUsSpecial4',
        description: '',
        icon: <BiNetworkChart size={24} />,
      },
      {
        label: 'containers.InternalProgramsContainer.IgnitionMakesUsSpecial5',
        description: '',
        icon: <BsGraphUp size={24} />,
      },
    ],
  },
];

export const selectionCriteriaIgnation = [
  {
    id: 1,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector1',
  },
  {
    id: 2,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector2',
  },
  {
    id: 3,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector3',
  },
  {
    id: 4,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector4',
  },
  {
    id: 5,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector5',
  },
  {
    id: 6,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector6',
  },
  {
    id: 7,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector7',
  },
  {
    id: 8,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector8',
  },
  {
    id: 9,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector9',
  },
  {
    id: 10,
    label: 'containers.InternalProgramsContainer.IgnitionTargetSector10',
  },
];
