import CryptoJS from 'crypto-js';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { BiEnvelope } from 'react-icons/bi';
import { RiEyeLine, RiEyeOffLine, RiLock2Line } from 'react-icons/ri';
import { TbCheck } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { Button } from 'src/components/common/ui/Button';
import InputFieldV2 from 'src/components/common/ui/InputFieldV2';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { postLoginService } from 'src/services/user.service';

import { loginFormInitialValues } from './login-form.data';
import { loginValidationSchema } from './login-form.validation';

const SECRET_KEY = 'The-garage-auto-login';

function LoginFormContainer() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const formik = useFormik({
    initialValues: loginFormInitialValues,
    validationSchema: loginValidationSchema(),
    onSubmit: async () => {
      await handleSubmit();
    },
  });

  const handleSubmit = async () => {
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        await postLoginService({ ...formik.values }, token);
        // Store the token securely in cookies
        if (rememberMe) {
          // Encrypt email and password before storing in cookies
          const encryptedEmail = CryptoJS.AES.encrypt(formik.values.email, SECRET_KEY).toString();
          // Set cookies with secure and HTTP-only flags
          Cookies.set('rememberEmail', encryptedEmail, {
            expires: 7,
            secure: true,
          });
        } else {
          // Remove cookies if "Remember Me" is not checked
          Cookies.remove('rememberEmail');
        }
        navigate('/');
      }
    } catch (error) {
      handleRequestError(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  useEffect(() => {
    const encryptedEmail = Cookies.get('rememberEmail');

    if (encryptedEmail) {
      const decryptedEmail = CryptoJS.AES.decrypt(encryptedEmail, SECRET_KEY).toString(
        CryptoJS.enc.Utf8
      );

      formik.values.email = decryptedEmail;

      setRememberMe(true);
    }
  }, []);
  return (
    <div className="flex flex-col justify-center h-full px-24 mobile:px-2 mobile:w-[calc(100vw-32px)]">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div className="flex flex-col justify-start gap-2 text-white">
          <p
            className={`${
              i18n.language === 'en' ? 'font-helveticaBold text-3xl' : 'font-arMyriad text-4xl'
            }`}
          >
            {t('app.shared.welcome')}
          </p>
          <p
            className={`${
              i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
            } text-base`}
          >
            {t('container.LoginFormContainer.enterEmailAndPassword')}
          </p>
        </div>
        {/* Login form */}
        <div className="flex flex-col mt-8 gap-3 mobile:gap-6">
          <InputFieldV2
            name="email"
            type="text"
            label={`${t('app.shared.email.requierd')}`}
            icon1={<BiEnvelope className="text-white" size={20} />}
            isValid={formik.touched.email && !formik.errors.email}
            value={formik.values.email != null ? formik.values.email : ''}
            errorMsg={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            onChange={(e) => {
              formik.setFieldValue('email', e.target.value);
              formik.handleChange(e);
            }}
          />
          <InputFieldV2
            name="password"
            type={showPassword ? 'password' : 'text'}
            label={`${t('app.shared.password')}`}
            icon1={<RiLock2Line className="text-white" size={20} />}
            icon2={
              showPassword ? (
                <RiEyeOffLine
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  className="text-gray-400 cursor-pointer"
                  size={20}
                />
              ) : (
                <RiEyeLine
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  className="text-gray-400 cursor-pointer"
                  size={20}
                />
              )
            }
            isValid={formik.touched.password && !formik.errors.password}
            value={formik.values.password != null ? formik.values.password : ''}
            errorMsg={
              formik.touched.password && formik.errors.password ? formik.errors.password : ''
            }
            onChange={(e) => {
              formik.setFieldValue('password', e.target.value);
              formik.handleChange(e);
            }}
          />
        </div>
        {/* Remember me & forgot password */}
        <div
          className={`flex flex-row mt-3 mobile:mt-6 justify-between items-center text-white ${
            i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
          } text-xs`}
        >
          <label className="flex flex-row gap-1 items-end relative">
            <input
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              id="remember"
              type="checkbox"
              name="Remember"
              value="Remember"
              className="peer appearance-none w-[18px] h-[18px] border-white border rounded checked:bg-primary checked:border-primary"
            />
            <TbCheck
              size={16}
              className="absolute mx-px mb-px pointer-events-none peer-checked:block stroke-white hidden"
            />
            {t('form.LoginFormContainer.rememberMe')}
          </label>
          <div
            onClick={() => navigate(appRoutesObj.getForgotPasswordPagePath())}
            className="cursor-pointer"
          >
            {t('app.shared.forgotYourPassword')}
          </div>
        </div>
        {/* Submit login button */}
        <Button
          className={`flex flex-row items-center justify-center h-10 w-full my-6 ${
            i18n.language === 'en' ? 'font-helveticaBold text-xs' : 'font-arMyriad text-base'
          }`}
          type="submit"
        >
          {t('form.LoginFormContainer.login')}
        </Button>
        <div
          className={`flex justify-center text-neutral-400 ${
            i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
          }`}
        >
          {t('form.LoginFormContainer.dontHaveAccount')}

          <div
            className="text-white px-2 cursor-pointer"
            onClick={() => navigate(appRoutesObj.getSignUpPagePath())}
          >
            {' '}
            {t('form.LoginFormContainer.register')}
          </div>
        </div>
      </form>
    </div>
  );
}

export default LoginFormContainer;
