import { useFormik } from 'formik';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { SignUpCardComponent } from 'src/components/common/sign-up-card-component';
import { Button } from 'src/components/common/ui/Button';
import GridView from 'src/components/common/ui/grid-view';
import InputFieldSignUp2 from 'src/components/common/ui/input-sigin-up2-component';
import InputFieldSignUp from 'src/components/common/ui/input-sign-up-component';
import InputField from 'src/components/common/ui/InputField';
import PasswordCheckComponent from 'src/components/user/password-check-component';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { signUpService } from 'src/services/sign-up-service';

import { signUpType } from './sign-up-form-data';
import { signUpValidationSchema } from './sign-up-form.validation';

function SignUpFormContainer() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: signUpType,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: signUpValidationSchema(),
  });
  const handleSubmit = async () => {
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        await signUpService({ ...formik.values }, token);
        navigate(appRoutesObj.getLoginPagePath());
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div className="flex flex-col h-full w-full justify-center items-center mobile:py-0 mobile:px-2 mobile:w-[calc(100vw-32px)]">
      <SignUpCardComponent
        title={'app.shared.welcome'}
        description={'container.SignUPFormContainer.supTitle'}
      />
      <div className="flex flex-col justify-center items-center">
        <div className="largeLaptop:h-auto laptop:h-auto mobile:h-auto tablet:h-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            className="flex flex-col items-center justify-center"
          >
            <div className="w-[404px] mobile:px-2 mobile:w-[calc(100vw-32px)] overflow-y-auto h-72 mobile:h-auto">
              <GridView column={'1'}>
                <InputField
                  label="container.SignUPFormContainer.name"
                  name="name"
                  isValid={formik.touched.name && !formik.errors.name}
                  value={formik.values.name != null ? formik.values.name : ''}
                  errorMsg={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                  onChange={(e) => {
                    formik.setFieldValue('name', e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="container.SignUPFormContainer.userName"
                  name="username"
                  isValid={formik.touched.username && !formik.errors.username}
                  value={formik.values.username != null ? formik.values.username : ''}
                  errorMsg={
                    formik.touched.username && formik.errors.username ? formik.errors.username : ''
                  }
                  onChange={(e) => {
                    formik.setFieldValue('username', e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="app.shared.email.requierd"
                  name="email"
                  isValid={formik.touched.email && !formik.errors.email}
                  value={formik.values.email != null ? formik.values.email : ''}
                  errorMsg={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                  onChange={(e) => {
                    formik.setFieldValue('email', e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="container.SignUPFormContainer.phoneNumber"
                  name="mobile"
                  helperText={''}
                  minLength={9}
                  isValid={formik.touched.mobile && !formik.errors.mobile}
                  value={formik.values.mobile != null ? formik.values.mobile : NaN}
                  onChange={(e) => {
                    formik.setFieldValue('mobile', e.target.value);
                    formik.handleChange(e);
                  }}
                  errorMsg={
                    formik.touched.mobile && formik.errors.mobile ? formik.errors.mobile : ''
                  }
                />
                <InputFieldSignUp
                  label="container.SignUPFormContainer.password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  icon2={
                    showPassword ? (
                      <RiEyeOffLine
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        className="text-gray-400 cursor-pointer"
                        size={20}
                      />
                    ) : (
                      <RiEyeLine
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        className="text-gray-400 cursor-pointer"
                        size={20}
                      />
                    )
                  }
                  isValid={formik.touched.password && !formik.errors.password}
                  value={formik.values.password != null ? formik.values.password : ''}
                  onChange={(e) => {
                    formik.setFieldValue('password', e.target.value);
                    formik.handleChange(e);
                  }}
                  errorMsg={
                    formik.touched.password && formik.errors.password ? formik.errors.password : ''
                  }
                />

                {formik.touched.password && formik.errors.password && (
                  <>
                    <PasswordCheckComponent
                      rxp="^(?=.*[A-Z])"
                      value={formik.values.password}
                      text="form.validation.passwordCheckComponent.uppercase"
                    />
                    <PasswordCheckComponent
                      rxp="^(?=.*[a-z])"
                      value={formik.values.password}
                      text="form.validation.passwordCheckComponent.lowercase"
                    />
                    <PasswordCheckComponent
                      rxp="^(?=.*[0-9])"
                      value={formik.values.password}
                      text="form.validation.passwordCheckComponent.number"
                    />
                    <PasswordCheckComponent
                      rxp="^(?=.*[%@$!*?&])"
                      value={formik.values.password}
                      text="form.validation.passwordCheckComponent.character"
                    />
                    <PasswordCheckComponent
                      rxp="^(.{8,}$)"
                      value={formik.values.password}
                      text="form.validation.passwordCheckComponent.long"
                    />
                  </>
                )}

                <InputFieldSignUp2
                  label="container.SignUPFormContainer.confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  icon2={
                    showConfirmPassword ? (
                      <RiEyeOffLine
                        onClick={() => {
                          setshowConfirmPassword(!showConfirmPassword);
                        }}
                        className="text-gray-400 cursor-pointer"
                        size={20}
                      />
                    ) : (
                      <RiEyeLine
                        onClick={() => {
                          setshowConfirmPassword(!showConfirmPassword);
                        }}
                        className="text-gray-400 cursor-pointer"
                        size={20}
                      />
                    )
                  }
                  isValid={formik.touched.confirmPassword && !formik.errors.confirmPassword}
                  value={formik.values.confirmPassword != null ? formik.values.confirmPassword : ''}
                  errorMsg={
                    formik.touched.confirmPassword && formik.errors.confirmPassword
                      ? formik.errors.confirmPassword
                      : ''
                  }
                  onChange={(e) => {
                    formik.setFieldValue('confirmPassword', e.target.value);
                    formik.handleChange(e);
                  }}
                />
              </GridView>
            </div>
            <div className="w-[404px] mobile:w-[calc(100vw-48px)] my-6">
              <Button
                className={`w-[404px] font-bold ${
                  i18n.language === 'en' ? 'font-helveticaBold text-xs' : 'font-arMyriad text-base'
                } `}
                type="submit"
              >
                {t('container.SignUPFormContainer.createAccount')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpFormContainer;
