import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IoMdClose } from 'react-icons/io';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import PopupCard from 'src/components/common/PopupCard';
import EventDetails from 'src/components/user/event-details-components';
import HiddenDisruptFormContainer from 'src/containers/user/disrupt-container/hidden-disrupt-form-container';

function DisruptHiddenFormPage() {
  return (
    <Layout>
      <PopupCard
        link={`${appRoutesObj.getBasePath()}`}
        icon={<IoMdClose size={32} className="text-white" />}
      >
        <>
          <EventDetails
            imgSrc={'/assets/images/disrupt-join.png'}
            imgAlt={'logo'}
            dateKey={'component.HiddenDisruptTimeComponent.date'}
            timeKey={'app.shared.tiem.7pm'}
            locationKey={'app.shared.theGarage'}
          />
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}>
            <HiddenDisruptFormContainer />
          </GoogleReCaptchaProvider>
        </>
      </PopupCard>
    </Layout>
  );
}

export default DisruptHiddenFormPage;
