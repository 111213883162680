import { onlyLetters_Ar_En, onlyNumbers_En, userName } from 'src/core/statics/regex';
import * as Yup from 'yup';

export const signUpValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, 'form.validation.first.name.no.numbers')
      .required('form.validation.name.required')
      .min(2, 'form.validation.name.min.2')
      .max(50, 'form.validation.name.max.50'),
    username: Yup.string()
      .trim()
      .matches(userName, 'form.validation.first.userName')
      .required('form.validation.user.name.required')
      .min(4, 'form.validation.user.name.min.4')
      .max(20, 'form.validation.user.name.max.20'),
    email: Yup.string()
      .trim()
      .required('form.validation.email.required')
      .max(50, 'form.validation.email.max.50')
      .email('form.validation.email.valid.format'),
    mobile: Yup.string()
      .trim()
      .required('form.validation.mobile.required')
      .matches(onlyNumbers_En, 'form.validation.mobile.no.numbers')
      .min(9, 'form.validation.mobile.min.9')
      .max(15, 'form.validation.mobile.max.15'),
    password: Yup.string()
      .required('app.shared.validation.required')
      .min(8, 'form.validation.first.password.min.8')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[%@$!*?&])[A-Za-z\d%@$!*?&]{8,}$/, ''),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'form.error.confirmPassword.matching')
      .required('form.validation.required.confirmPassword'),
  });
