import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiArrowLeft } from 'react-icons/pi';
import { RiEyeLine, RiEyeOffLine, RiLock2Line } from 'react-icons/ri';
import { useNavigate, useLocation } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { Button } from 'src/components/common/ui/Button';
import InputFieldV2 from 'src/components/common/ui/InputFieldV2';
import LoginCardComponent from 'src/components/user/login-card-compoent';
import { SizesEnum } from 'src/core/enums/ui.enums';
import { ColorsEnum } from 'src/core/enums/ui.enums';
import { VariantsEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import getLanguageBasedClassName from 'src/core/helpers/languageBasedClassName';
import { ChangePasswordDataType } from 'src/core/types/profile-form-type';
import { postVerifyPassword } from 'src/services/profile.service';

import { initialChangePasswordValues } from './profile-initialValues';
import { VerifyPasswordValidationSchema } from './profile-validationSchema';

export default function ChangePasswordContainer() {
  const [showPassword, setShowPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const profileData = location.state?.profileData;
  const handleSubmit = async (values: ChangePasswordDataType) => {
    try {
      const verifyPassword = await postVerifyPassword({
        email: profileData.email,
        password: values.password,
      });
      if (verifyPassword === false) {
        handleRequestError('Invalid password');
      } else {
        navigate(appRoutesObj.getNewPasswordPagePath());
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div className="h-full w-full flex justify-center items-center py-10">
      <LoginCardComponent>
        <div className="flex flex-col h-full justify-center gap-y-9 items-center mx-auto  mobile:py-20 tablet:py-20 px-24">
          <div className="flex flex-col w-full gap-y-7">
            <PiArrowLeft
              className={`cursor-pointer ${i18n.language === 'en' ? 'rotate-0' : 'rotate-180'}`}
              onClick={() => navigate(appRoutesObj.getProfilePagePath())}
              color="white"
              size={32}
            />

            <div className="flex flex-col gap-y-2">
              <p
                className={`text-white ${getLanguageBasedClassName(
                  'text-3xl font-helveticaBold',
                  'text-4xl font-arMyriad'
                )}`}
              >
                {t('change.password.changePasswordTitle')}
              </p>
              <p
                className={`text-white  text-base ${getLanguageBasedClassName(
                  'font-helveticaMedium',
                  'font-arMyriad'
                )}`}
              >
                {t('change.password.enterOldPassword')}
              </p>
            </div>
          </div>
          <Formik
            initialValues={initialChangePasswordValues}
            validationSchema={VerifyPasswordValidationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, handleChange, errors, touched }) => (
              <Form className="flex flex-col gap-y-6 w-full">
                <div className="flex flex-col gap-y-6 w-full">
                  <InputFieldV2
                    type={showPassword ? 'text' : 'password'}
                    label="profile.input.password"
                    icon1={<RiLock2Line size={20} color="white" />}
                    icon2={
                      showPassword ? (
                        <RiEyeOffLine
                          size={20}
                          color="white"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <RiEyeLine
                          size={20}
                          color="white"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )
                    }
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    errorMsg={errors.password}
                    isValid={touched.password && !errors.password}
                  />
                  <p
                    className="text-white text-xs font-helveticaLight pt-0 cursor-pointer"
                    onClick={() => navigate(appRoutesObj.getForgotPasswordPagePath())}
                  >
                    {t('change.password.dontRemberOldPassword')}
                  </p>
                </div>
                <Button
                  type="submit"
                  className="w-full"
                  variant={VariantsEnum.Filled}
                  color={ColorsEnum.Primary}
                  size={SizesEnum.Small}
                >
                  {t('app.shared.send')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </LoginCardComponent>
    </div>
  );
}
