import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SharedSectionComponent from 'src/components/shard-Section';
import CardComponent from 'src/components/user/card-component';
import CommunityCardComponent from 'src/components/user/comunnaty-card-component';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { linksData } from 'src/core/statics/community.static';
import { getPlusCompaniesType } from 'src/core/types/plus.type';
import { getPlusCompanies } from 'src/services/plus.service';

import Carousel from './Carousel';

function CommunityContainer() {
  const { i18n } = useTranslation();
  const [PlusNumber, setPlusNumber] = useState<number>(1);
  const [data, setData] = useState<getPlusCompaniesType>();
  const initialData = { max_count: null, result_list: [], total_count: 0 };
  const PlusList = useCallback(async () => {
    setPlusNumber(1);
    try {
      const result = await getPlusCompanies(PlusNumber, 20, 1);
      setData(result);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);
  useEffect(() => {
    PlusList();
  }, [PlusList]);

  return (
    <div className="w-full">
      <SharedSectionComponent
        component={
          <div className="grid lg:grid-cols-7 w-full mobile:grid-cols-1 tablet:grid-cols-1 ">
            <CardComponent className="lg:col-span-5">
              <Carousel Cards={data || initialData} autoPlay={false} />
            </CardComponent>

            <div
              className={`px-2 text-white ${
                i18n.language === 'ar'
                  ? 'font-arMyriad text-2xl'
                  : 'font-helveticaMedium largeLaptop:text-[13px] laptop:text-xs'
              } col-span-2 grid gap-2 tablet:pt-4 mobile:pt-4 laptop:gap-1 text-center tablet:grid tablet:grid-cols-2 largeLaptop:px-2 w-full`}
            >
              <CommunityCardComponent title={linksData[0].title} path={linksData[0].path} />
              <CommunityCardComponent title={linksData[1].title} path={linksData[1].path} />
              <div className="col-span-2">
                <CommunityCardComponent title={linksData[2].title} path={linksData[2].path} />
              </div>
            </div>
          </div>
        }
        title={'app.shared.community'}
      />
    </div>
  );
}
export default CommunityContainer;
