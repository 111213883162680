export const InputList = [
  {
    label: 'component.user.SidebarComponent.eventNumber',
    Number: '60',
    subtext: '',
  },
  {
    label: 'app.shared.startup',
    Number: '230',
    subtext: '',
  },
  {
    label: 'component.user.SidebarComponent.startupValue',
    Number: '216',
    subtext: 'M',
  },
  {
    label: 'component.user.SidebarComponent.jobsNumber',
    Number: '650',
    subtext: '',
  },
];
