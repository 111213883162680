import { useFormik } from 'formik';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import Popup from 'src/components/common/pop-up';
import { ReportProblemButtonComponent } from 'src/components/common/report-problem-button-component';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import SelectComponent2, { optionsList } from 'src/components/common/ui/select-component2';
import { RoleEnum } from 'src/core/enums/service.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { garagePlusFormService } from 'src/services/garage-plus-form.service';

import { initialValues } from './registration-form.data';
import { validationSchema } from './registration-form.validation';

export default function RegistrationForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [successfully, setSuccessfully] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const roleOptionsList: optionsList[] = Object.entries(RoleEnum).map(([key, value]) => ({
    value: t(`container.registrationForm.statement.${value}`),
    key,
  }));

  const handleSubmit = async () => {
    setIsLoading(!isLoading);
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        await garagePlusFormService(formik.values, token);
        setSuccessfully(!successfully);
      } else {
        handleRequestError("Couldn't verify Google Recaptcha, refresh the page please");
      }
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: validationSchema,
  });
  // Helper function to get the value of a field, handling empty strings
  const getFieldValue = (field: keyof typeof initialValues) => {
    const value = formik.values[field];

    return typeof value === 'string' && value.trim().length === 0
      ? formik.initialValues[field]
      : value;
  };

  return (
    <div className="pt-4 relative w-full">
      <GridView column="2">
        <InputField
          label="app.shared.fullName"
          name="full_name"
          isValid={formik.touched.full_name && !formik.errors.full_name}
          value={getFieldValue('full_name')}
          errorMsg={formik.touched.full_name ? formik.errors.full_name : ''}
          onChange={formik.handleChange}
        />
        <InputField
          label="app.shared.email.requierd"
          name="email"
          isValid={formik.touched.email && !formik.errors.email}
          value={formik.values.email.trim()}
          errorMsg={formik.touched.email ? formik.errors.email : ''}
          onChange={formik.handleChange}
        />
        <InputField
          label="container.registrationForm.title"
          name="title"
          isValid={formik.touched.title && !formik.errors.title}
          value={getFieldValue('title')}
          errorMsg={formik.touched.title ? formik.errors.title : ''}
          onChange={formik.handleChange}
        />
        <InputField
          label="app.shared.organization"
          name="organization"
          isValid={formik.touched.organization && !formik.errors.organization}
          value={getFieldValue('organization')}
          errorMsg={formik.touched.organization ? formik.errors.organization : ''}
          onChange={formik.handleChange}
        />
      </GridView>
      <div className="grid grid-cols-1 pt-4 gap-x-7 gap-y-4">
        <SelectComponent2
          name="role"
          selectLabel={t('container.registrationForm.statement') || ''}
          options={roleOptionsList}
          value={formik.values.role || ''}
          errorMsg={formik.touched.role ? formik.errors.role : ''}
          onChange={formik.handleChange}
        />
      </div>
      <div className="flex justify-center items-end absolute -bottom-28 w-full">
        <ReportProblemButtonComponent
          isLoading={isLoading}
          title={t('app.shared.send') || ''}
          onClick={() => {
            formik.handleSubmit();
            window.scrollTo(0, 0);
          }}
        />
      </div>
      {successfully && (
        <div className="z-50 absolute h-full w-full top-6 mobile:top-2 mobile:left-0 tablet:top-0">
          <div className="flex justify-center mx-auto">
            <Popup
              body="popup-successfully"
              onClose={() => {
                setSuccessfully(false);
                formik.resetForm();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
