import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiArrowLeft } from 'react-icons/pi';
import { RiEyeLine, RiEyeOffLine, RiLock2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { Button } from 'src/components/common/ui/Button';
import InputFieldV2 from 'src/components/common/ui/InputFieldV2';
import LoginCardComponent from 'src/components/user/login-card-compoent';
import { SizesEnum } from 'src/core/enums/ui.enums';
import { ColorsEnum } from 'src/core/enums/ui.enums';
import { VariantsEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import getLanguageBasedClassName from 'src/core/helpers/languageBasedClassName';
import { NewPasswordDataType } from 'src/core/types/profile-form-type';
import { postChangePasswordService } from 'src/services/profile.service';

import { initialNewPasswordValues } from './profile-initialValues';
import { NewPasswordValidationSchema } from './profile-validationSchema';

export default function ChangePasswordContainer() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (values: NewPasswordDataType) => {
    try {
      setLoading(true);
      await postChangePasswordService({
        password: values.new_password,
      });
      handleRequestError.success('container.CV.confirmation.updated');
      navigate(appRoutesObj.getProfilePagePath());
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full w-full flex justify-center items-center py-10">
      <LoginCardComponent>
        <div className="flex flex-col h-full justify-center gap-y-9 items-center mx-auto  mobile:py-20 tablet:py-20 px-24">
          <div className="flex flex-col w-full gap-y-7">
            <PiArrowLeft
              className={`cursor-pointer ${i18n.language === 'en' ? 'rotate-0' : 'rotate-180'}`}
              onClick={() => navigate(appRoutesObj.getChangePasswordPagePath())}
              color="white"
              size={32}
            />
            <div className="flex flex-col gap-y-2">
              <p
                className={`text-white ${getLanguageBasedClassName(
                  'text-3xl font-helveticaBold',
                  'text-4xl font-arMyriad'
                )}`}
              >
                {t('change.password.newPassword')}
              </p>
              <p
                className={`text-white  text-base ${getLanguageBasedClassName(
                  'font-helveticaMedium',
                  'font-arMyriad'
                )}`}
              >
                {t('change.password.newPasswordDescription')}
              </p>
            </div>
          </div>
          <Formik
            initialValues={initialNewPasswordValues}
            validationSchema={NewPasswordValidationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, handleChange, errors, touched }) => (
              <Form className="flex flex-col gap-y-6 w-full">
                <div className="flex flex-col gap-y-4 w-full">
                  <InputFieldV2
                    type={showPassword ? 'text' : 'password'}
                    label="profile.input.password"
                    icon1={<RiLock2Line size={20} color="white" />}
                    icon2={
                      showPassword ? (
                        <RiEyeOffLine
                          size={20}
                          color="white"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <RiEyeLine
                          size={20}
                          color="white"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )
                    }
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    errorMsg={errors.password}
                    isValid={touched.password && !errors.password}
                  />
                  <div className="flex flex-col gap-y-3 w-full">
                    <InputFieldV2
                      type={showConfirmNewPassword ? 'text' : 'password'}
                      label="app.shared.confirmPassword"
                      icon1={<RiLock2Line size={20} color="white" />}
                      icon2={
                        showConfirmNewPassword ? (
                          <RiEyeOffLine
                            size={20}
                            color="white"
                            onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                          />
                        ) : (
                          <RiEyeLine
                            size={20}
                            color="white"
                            onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                          />
                        )
                      }
                      name="new_password"
                      value={values.new_password}
                      onChange={handleChange}
                      errorMsg={errors.new_password}
                      isValid={touched.new_password && !errors.new_password}
                    />
                    <p className="text-gray-400 text-base font-helvetica">
                      {t('change.password.cratiera')}
                    </p>
                  </div>
                </div>
                <Button
                  className="w-full !text-xs"
                  variant={VariantsEnum.Filled}
                  color={ColorsEnum.Primary}
                  size={SizesEnum.Medium}
                  loading={loading}
                  disabled={loading}
                >
                  {t('change.password.saveChanges')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </LoginCardComponent>
    </div>
  );
}
