import { Form } from 'formik';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import Checkbox from 'src/components/common/ui/Checkbox';
import SelectComponent2, { optionsList } from 'src/components/common/ui/CV-select-component';
import DateField from 'src/components/common/ui/DateField2';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import TextAreaField from 'src/components/common/ui/textArea';
import SaveCancelButton from 'src/components/user/V2/save-cance-button';
import i18n from 'src/core/configs/i18n';
import { CompanyIndustryArEnum, CompanyIndustryEnum } from 'src/core/enums/profile.enum';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { countries_en } from 'src/core/statics/static';
import useProfileStore from 'src/core/stores/setUserData';
import { WorkExperienceType } from 'src/core/types/CV.type';
import { getCVService, putCVService } from 'src/services/CV.service';

import { validationSchema } from './work-experience-form.validation';
import { cvInitialValues } from '../initialValues';
import { useExperienceData } from './useExperienceData';

interface IWorkExperienceFormProps {
  setIsEditing: (value: boolean) => void;
  selectedId?: string;
}

function WorkExperienceFormContainer({ setIsEditing, selectedId }: IWorkExperienceFormProps) {
  const { experienceData, setExperienceData } = useExperienceData(selectedId);
  const [noExpiredDate, setNoExpiredDate] = useState(experienceData?.no_expired_date ?? false);
  const { data, setUserData } = useProfileStore();
  const CompanyIndustryOptionsList: optionsList[] = Object.entries(CompanyIndustryEnum).map(
    ([key, value]) => ({
      value,
      key,
    })
  );
  const CompanyIndustryArOptionsList: optionsList[] = Object.entries(CompanyIndustryArEnum).map(
    ([key, value]) => ({
      value,
      key,
    })
  );

  useEffect(() => {
    setNoExpiredDate(experienceData?.no_expired_date ?? false);
  }, [experienceData]);

  useEffect(() => {
    setNoExpiredDate(experienceData?.no_expired_date ?? false);
  }, [experienceData]);

  const handleSubmit = async (values: Partial<WorkExperienceType>) => {
    try {
      const sanitizedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value === '' ? null : value])
      );

      const updatedExperience = [...(data?.experience || []), sanitizedValues];
      await putCVService({
        ...data,
        experience: updatedExperience,
      });
      const response = await getCVService();
      setExperienceData(response);
      setUserData(response);
      handleRequestError.success('container.CV.confirmation.saved');
      setIsEditing(false);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <Formik
      initialValues={
        experienceData ??
        ({
          ...cvInitialValues.experience?.[0],
          no_expired_date: false,
        } as Partial<WorkExperienceType>)
      }
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, errors, touched, setFieldValue, resetForm }) => (
        <Form>
          <GridView column="1">
            <InputField
              name="name"
              label="container.WorkExperienceContainer.companyName"
              isValid={touched.name && !errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={touched.name ? errors.name : ''}
            />
            <SelectComponent2
              name="industry"
              selectLabel="container.WorkExperienceContainer.companyIndustry"
              options={
                i18n.language === 'en' ? CompanyIndustryOptionsList : CompanyIndustryArOptionsList
              }
              value={values.industry ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={touched.industry ? errors.industry : ''}
              isValid={touched.industry && !errors.industry}
            />
            <SelectComponent2
              name="location"
              selectLabel="container.WorkExperienceContainer.companyLocation"
              options={countries_en.map((country) => ({ value: country, key: country }))}
              isValid={touched.location && !errors.location}
              value={values.location}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={touched.location ? errors.location : ''}
            />
            <div>
              <div className="flex mobile:flex-col gap-4">
                <DateField
                  name="start_date"
                  isValid={touched.start_date && !errors.start_date}
                  value={values.start_date ?? ''}
                  onChange={handleChange}
                  acceptPastDate={true}
                  acceptFutureDate={false}
                  label="container.WorkExperienceContainer.startDate"
                  errorMsg={touched.start_date ? errors.start_date : ''}
                />
                {!noExpiredDate && (
                  <DateField
                    name="end_date"
                    isValid={touched.end_date && !errors.end_date}
                    value={values.end_date ?? ''}
                    onChange={(e) => setFieldValue('end_date', e.target.value)}
                    acceptPastDate
                    acceptFutureDate
                    label="app.shared.endDate"
                    errorMsg={touched.end_date ? errors.end_date : ''}
                  />
                )}
              </div>
              <Checkbox
                label="container.WorkExperienceContainer.present"
                name="no_expired_date"
                checked={noExpiredDate}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setNoExpiredDate(isChecked);
                  setFieldValue('no_expired_date', isChecked);
                  if (isChecked) {
                    setFieldValue('end_date', null);
                  }
                }}
              />
            </div>
            <TextAreaField
              label="container.WorkExperienceContainer.jobDescription"
              name="description"
              limit={500}
              isValid={touched.description && !errors.description}
              value={values.description ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={touched.description ? errors.description : ''}
            />
          </GridView>
          <div className="flex justify-center w-full items-center mx-auto gap-2">
            <SaveCancelButton
              onCancel={() => {
                resetForm();
                setIsEditing(false);
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default WorkExperienceFormContainer;
