import React, { useState, useEffect, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCalendar } from 'react-icons/hi';
import { PiCaretLeft, PiCaretRight } from 'react-icons/pi';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import i18n from 'src/core/configs/i18n';
import { DirectionEnum } from 'src/core/enums/ui.enums';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import 'react-multi-date-picker/styles/colors/yellow.css';
import '../../../../src/App.css';

// Helper function to parse YYYY-MM-DD formatted date string to DateObject
const parseDateString = (dateString: string): DateObject => {
  const [year, month, day] = dateString.split('-').map(Number);

  return new DateObject({ day, month, year });
};

interface DateFieldProps {
  limit?: number;
  helperText?: string;
  label?: string;
  error?: boolean;
  errorMsg?: string;
  isValid?: boolean;
  value?: DateObject | string; // Accept DateObject or string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  dir?: DirectionEnum;
  isBirthDate?: boolean;
  disabled?: boolean;
  acceptFutureDate?: boolean;
  acceptPastDate?: boolean;
}

const DateField = ({
  label = 'Label',
  limit,
  dir = i18n.language === 'en' ? DirectionEnum.LTR : DirectionEnum.RTL,
  helperText = '',
  errorMsg = '',
  isValid = true,
  value,
  acceptFutureDate = false,
  acceptPastDate = false,
  onChange,
  name,
  isBirthDate = false,
  disabled = false,
}: DateFieldProps): JSX.Element => {
  const { t } = useTranslation();

  const today = new DateObject();
  const nextDay = new DateObject(today).add(1, 'day');
  const thirtyYearsAgo = new DateObject().subtract(30, 'year');
  const [internalValue, setInternalValue] = useState<DateObject | undefined>(
    !isBirthDate ? nextDay : undefined
  );

  useEffect(() => {
    if (typeof value === 'string') {
      // Initialize DateObject from date string in YYYY-MM-DD format
      setInternalValue(parseDateString(value));
    } else if (value instanceof DateObject) {
      setInternalValue(value); // Use existing DateObject
    }
  }, [value]);

  const handleDateChange = (date: DateObject) => {
    setInternalValue(date);
    const formattedDate = date.format('YYYY-MM-DD'); // Format date as YYYY-MM-DD
    if (onChange) {
      onChange({
        target: {
          name: name || '',
          value: formattedDate, // Send date in YYYY-MM-DD format
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const valueLength = (internalValue ? internalValue.format('YYYY-MM-DD') : '').length;

  const getError = () => {
    if (errorMsg !== '') {
      isValid = false;
      return 'border-red-500 disabled:focus:border-2';
    }
    if (limit !== undefined && valueLength > limit) {
      isValid = false;
      return 'border-red-500 focus:border-2 ';
    }
    isValid = true;
    return 'border-none';
  };

  const getLabelVariant = () => {
    return `flex text-base peer-focus:text-base peer-focus:transform peer-focus:-translate-y-3 peer-focus:scale-95 text-opacity-80 bg-transparent px-1
    ${
      dir === DirectionEnum.LTR
        ? 'left-0 ml-3 peer-focus:-translate-x-1'
        : 'right-5 peer-focus:translate-x-3'
    }
    ${
      internalValue && dir === DirectionEnum.LTR && isValid
        ? `transform -translate-y-3 -translate-x-1 scale-95`
        : internalValue && dir === DirectionEnum.RTL && isValid
          ? 'transform -translate-y-3 translate-x-3 scale-95'
          : (!isValid && internalValue) || !isValid
            ? `transform -translate-y-5 scale-95 peer-focus:-translate-y-5`
            : internalValue
              ? 'transform  translate-x-3 '
              : ''
    }`;
  };

  const getInputVariant = () => {
    return `border-[1px] rounded-md placeholder:opacity-0 focus:placeholder:opacity-100 duration-100 px-4  ${getError}`;
  };

  return (
    <div className="relative flex flex-col justify-center w-full mx-auto" dir={dir}>
      <input
        className={`peer relative h-14 mobile:h-12 tablet:h-12 focus:outline-none ${
          i18n.language === 'en' ? 'font-helveticaMedium text-base' : 'font-arMyriad text-xl'
        }
        ${(limit && limit < valueLength) || (errorMsg !== '' && 'disabled:border-red-500')}
         pt-5 bg-neutral-500/10 transition text-white disabled:text-gray-400 
         border-neutral-500/10 relative  ${getInputVariant()}`}
        value={internalValue ? internalValue.format('YYYY-MM-DD') : ''} // Display date as YYYY-MM-DD
        disabled={disabled}
      />
      <div
        className={`absolute top-4 ${i18n.language === 'en' ? 'right-7' : 'left-4'}  text-white`}
      >
        <DatePicker
          disabled={disabled}
          maxDate={acceptFutureDate ? undefined : isBirthDate ? today : undefined}
          minDate={acceptPastDate ? thirtyYearsAgo : !isBirthDate ? nextDay : undefined}
          value={internalValue}
          showOtherDays
          renderButton={(
            direction: string,
            handleClick: MouseEventHandler<HTMLButtonElement> | undefined
          ) => (
            <button onClick={handleClick}>
              {direction === 'right' ? (
                <PiCaretRight size={32} className="text-primary-500" />
              ) : (
                <PiCaretLeft size={32} className="text-primary-500" />
              )}
            </button>
          )}
          calendarPosition={i18n.language === 'en' ? 'bottom-right' : 'bottom-left'}
          onChange={handleDateChange}
          className="bg-dark yellow"
          render={(value, openCalendar) => (
            <HiOutlineCalendar onClick={openCalendar} className="text-gray-400" size={20} />
          )}
        />
      </div>
      <label
        className={`absolute ${value ? '' : 'top-6'} ${
          i18n.language === 'en' ? 'font-helveticaMedium text-base' : 'font-arMyriad text-xl'
        } transition peer-focus:text-gray-400 duration-200 text-gray-400  ${
          limit ? 'disabled:!bg-transparent bottom-1/2' : 'bottom-1/3'
        } ${getLabelVariant()} `}
      >
        {t(label)}
      </label>
      <div
        className={`flex justify-between px-2 py-1 text-xs text-gray-900 ${
          i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
        }`}
      >
        {(limit && limit < valueLength) || errorMsg !== '' ? (
          <p className={`${getError()} text-red-500`}>{t(errorMsg)}</p>
        ) : (
          <p className={`${getError()}`}>{t(helperText)}</p>
        )}
        {limit && (
          <span
            className={`${valueLength > limit ? 'text-red-500' : ''} ${
              i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
            }`}
          >
            {valueLength}/{limit}
          </span>
        )}
      </div>
    </div>
  );
};

export default DateField;
