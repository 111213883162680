import { appRoutesObj } from 'src/app.paths';

export const ActivitiesElement = [
  {
    ActivitiesType: 'disrupt',
    img: 'assets/images/disrupt.svg',
    description: 'container.disruptTimerContainer.CommingSoon',
    link: appRoutesObj.getDisruptPagePath(),
    isStatic: false,
  },
  {
    ActivitiesType: 'garagePlus',
    description: 'container.disruptTimerContainer.CommingSoon',
    link: appRoutesObj.getGaragePlusPath(),
    isStatic: true,
    staticDate: 'component.plusTimeAndDateComponent.date',
    staticTime: 'app.shared.tiem.7pm',
  },
];

export const reservationItems = [
  {
    title: 'component.user.reservationComponent.BooktourTitle',
    description: 'component.user.reservationComponent.BooktourDescreption',
    link: appRoutesObj.getBookTourFormPagePath(),
  },
  {
    title: 'containers.BeOurPartnerFormContainer.title',
    description: 'containers.BeOurPartnerFormContainer.description',
    link: appRoutesObj.getBeOurPartnerFormPagePath(),
  },
  {
    title: 'containers.BookVenueFormContainer.title',
    description: 'containers.BookVenueFormContainer.description',
    link: appRoutesObj.getBookVenueFormPagePath(),
  },
];
