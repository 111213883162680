import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/ui/Button';
import i18n from 'src/core/configs/i18n';
import { ColorsEnum, SizesEnum, VariantsEnum } from 'src/core/enums/ui.enums';
interface CardComponentProps {
  title: string;
  title1: string;
  title2?: string;
  onEdit?: () => void;
  onDelete: () => void;
}

function CVCardComponent({ title, title1, title2, onEdit, onDelete }: CardComponentProps) {
  const { t } = useTranslation();
  const fontClass = i18n.language === 'en' ? 'font-helvetica' : 'font-arMyriad';

  return (
    <div
      className={`flex mobile:flex-col mobile:gap-5 justify-between w-full px-4 py-5 rounded-2xl bg-neutral-500/10 
        border border-gray-600/50 text-white ${fontClass}`}
    >
      <div className="flex flex-col gap-2 mobile:gap-3">
        <h2 className="text-lg font-helveticaLight">
          {title
            ?.toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (c) => c.toUpperCase()) || ''}
        </h2>
        <div className="flex flex-col">
          <p className="text-sm text-neutral-400">
            {title1
              ?.toLowerCase()
              .replace(/_/g, ' ')
              .replace(/\b\w/g, (c) => c.toUpperCase()) || ''}
          </p>
          <p className="text-sm text-neutral-400">
            {title2
              ?.toLowerCase()
              .replace(/_/g, ' ')
              .replace(/\b\w/g, (c) => c.toUpperCase()) || ''}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-2 mobile:w-fit">
        {onEdit && (
          <Button
            variant={VariantsEnum.Outline}
            onClick={onEdit}
            size={SizesEnum.Small}
            className="text-sm text-white !bg-neutral-500/10  !border-gray-600/50 "
          >
            {t('app.shared.edit')}
          </Button>
        )}
        <Button
          variant={VariantsEnum.Outline}
          color={ColorsEnum.Danger}
          onClick={onDelete}
          size={SizesEnum.Small}
          className="text-sm !px-3 "
        >
          {t('app.shared.delete')}
        </Button>
      </div>
    </div>
  );
}

export default CVCardComponent;
