import type { FormikProps } from 'formik';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BiUser } from 'react-icons/bi';
import { useImageUpload } from 'src/core/helpers/hooks/useImageUpload';

interface IUploadComponentProps<T = Record<string, unknown>> {
  image: string;
  onChange: (value: string, file?: File) => void;
  error?: string;
  name: string;
  id: string;
  hint: string;
  formik?: FormikProps<T>;
  label: string;
  preview?: boolean;
  maxSizeMB?: number;
  disabled?: boolean;
}

function Upload<T = Record<string, unknown>>({
  name,
  image,
  hint,
  formik,
  onChange,
  id,
  label,
  disabled,
  maxSizeMB,
  ...props
}: IUploadComponentProps<T>): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, uploadError, handleFileUpload } = useImageUpload({
    onChange,
    name,
    formik,
    maxSizeMB,
  });

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    else {
      const file = event.currentTarget.files?.item(0);
      if (file) {
        const fileSizeMB = file.size / (1024 * 1024);
        if (maxSizeMB && fileSizeMB > maxSizeMB) {
          return;
        }
        handleFileUpload(file);
      }
    }
  };

  const handleRemove = () => {
    if (disabled) return;
    else onChange('');
  };

  const renderUploadButton = (): JSX.Element => (
    <label>
      <div className={`text-yellow-500 ${disabled ? '' : 'hover:text-yellow-400'}`}>
        {t('container.GeneralInfoContainer.chooseImage')}
      </div>
      <input
        {...props}
        id={id}
        name={name}
        type="file"
        accept="image/jpg,image/jpeg,image/png"
        onChange={handleChange}
        className="sr-only"
        disabled={isLoading || disabled}
      />
    </label>
  );

  const renderUpdateButtons = (): JSX.Element => (
    <div className="grid grid-cols-2 gap-7">
      <label>
        <div className={`text-yellow-500 ${disabled ? '' : 'hover:text-yellow-400'}`}>
          {t('container.GeneralInfoContainer.update')}
        </div>
        <input
          {...props}
          id={id}
          name={name}
          type="file"
          accept="image/jpg,image/png"
          onChange={handleChange}
          className="sr-only"
          disabled={isLoading || disabled}
        />
      </label>
      <button
        type="button"
        disabled={isLoading || disabled}
        className="text-gray-400 order-first hover:text-gray-300 disabled:hover:text-gray-400"
        onClick={handleRemove}
      >
        {t('app.shared.delete')}
      </button>
    </div>
  );

  return (
    <div className="grid gap-2">
      <p className="text-white mobile:order-1">{label}</p>
      <div className="flex items-center gap-7 mobile:order-3">
        <label
          className={`flex items-center justify-center w-20 h-20 mobile:w-12 mobile:h-12 backdrop-blur-lg rounded-lg 
            bg-neutral/10 border ${
              props.error || uploadError ? 'border-red-500' : 'border-gray-600'
            }`}
        >
          {image ? (
            <img
              className="mobile:w-12 mobile:h-12 rounded-lg w-full h-full"
              src={image}
              alt="User uploaded image"
            />
          ) : (
            <BiUser className="text-3xl text-white mobile:text-lg" />
          )}
        </label>

        <div className="flex flex-col gap-2">
          {(uploadError || props.error) && (
            <p className="text-sm text-red-500">{t(uploadError || (props.error as string))}</p>
          )}
          {!uploadError && formik && (
            <p className="text-sm text-red-500">{t(formik.errors[name as keyof T] as string)}</p>
          )}

          {image ? renderUpdateButtons() : renderUploadButton()}
        </div>
      </div>
      <p className="text-gray-400 text-sm mobile:text-xs mobile:order-2">{hint}</p>
    </div>
  );
}

export default Upload;
