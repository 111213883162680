/* eslint-disable import/named */
import { Formik, useFormik, FormikTouched, FormikValues, FormikErrors } from 'formik';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import Popup from 'src/components/common/pop-up';
import ProgramSteps from 'src/components/common/program-steps';
import { RegistrationCardComponent } from 'src/components/common/registration-card-component';
import { SharedButtonComponent } from 'src/components/common/shared-button-component';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import SelectComponent from 'src/components/common/ui/select-component';
import { handleRequestError } from 'src/core/helpers/error.helper';
import * as list from 'src/core/statics/form-data/form-data';
import { BeOurPartnerList } from 'src/core/types/be-our-partner-type';
import { beOurPartnerApplication } from 'src/services/be-our-partner.service';

import { beOurPartnerList } from './be-our-partner-form-data';
import { validationSchema } from './be-our-partner-form-validation';

function BeOurPartnerFormContainer() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [successfully, setSuccessfully] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fieldsToCheck: string[] = ['firstName', 'lastName', 'phoneNo', 'email'];
  const fieldsToCheck2: string[] = ['company', 'position', 'partnershiptype'];
  const content = [
    { label: 'app.shared.personalInformation', color: '#737373' },
    { label: 'app.shared.additionalInformation', color: '#737373' },
  ];
  const isLastSection = activeIndex === content.length - 1;

  const formik = useFormik({
    initialValues: beOurPartnerList,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: validationSchema(),
  });
  const handleSubmit = async () => {
    setIsLoading(!isLoading);
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        await beOurPartnerApplication(
          {
            ...formik.values,
            first_name: formik.values.firstName,
            last_name: formik.values.lastName,
            partnership_type: formik.values.partnershiptype,
            mobile: formik.values.phoneNo,
          },
          token
        );
      }
      setSuccessfully(!successfully);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleBackClick = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + content.length) % content.length);
  };

  const handleNext = (
    fieldsToCheck: string[],
    formik: {
      setTouched: (touched: FormikTouched<BeOurPartnerList>) => void;
      values: FormikValues;
      errors: FormikErrors<BeOurPartnerList>;
    },
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any[]
  ) => {
    const currentStep = activeIndex;
    let fieldsToValidate: string[];

    if (currentStep === 0) {
      fieldsToValidate = fieldsToCheck;
    } else if (currentStep === 1) {
      fieldsToValidate = fieldsToCheck2;
    } else {
      return handleSubmit();
    }
    const newTouched: FormikTouched<BeOurPartnerList> = {};
    fieldsToValidate.forEach((field) => {
      newTouched[field] = true;
    });
    formik.setTouched(newTouched);
    const hasErrors = fieldsToValidate.some(
      (field) => !formik.values[field] || !!formik.errors[field]
    );
    if (!hasErrors) {
      setActiveIndex((prevIndex) => Math.min(prevIndex + 1, content.length - 1));
    }
  };
  const handlClick = () => {
    if (activeIndex !== 0) {
      handleBackClick();
    } else if (activeIndex === 0) {
      handleNext(fieldsToCheck, formik, setActiveIndex, content);
    } else {
      handleSubmit();
    }
  };

  return (
    <div className=" w-full h-full">
      <div>
        <RegistrationCardComponent
          title={'containers.BeOurPartnerFormContainer.title'}
          description={'containers.BeOurPartnerFormContainer.description'}
        >
          <div className="h-auto">
            <div className="flex gap-x-12 pt-10 laptop:pt-5 mobile:gap-x-6 tablet:gap-x-6 text-base text-neutral-500">
              {content.map((item, index) => (
                <ProgramSteps
                  activeIndex={activeIndex}
                  index={index}
                  color={item.color}
                  label={item.label}
                  key={index}
                  completed={activeIndex > index}
                  onClick={handlClick}
                />
              ))}
            </div>
            <div className="w-full">
              <Formik
                initialValues={beOurPartnerList}
                onSubmit={() => formik.submitForm()}
                validationSchema={formik.initialValues}
                validateOnChange
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  {activeIndex === 0 && (
                    <GridView column={'2'}>
                      <InputField
                        label="app.shared.firstName"
                        name="firstName"
                        isValid={formik.touched.firstName && !formik.errors.firstName}
                        value={formik.values.firstName != null ? formik.values.firstName : ''}
                        errorMsg={
                          formik.touched.firstName && formik.errors.firstName
                            ? formik.errors.firstName
                            : ''
                        }
                        onChange={(e) => {
                          formik.setFieldValue('firstName', e.target.value);
                          formik.handleChange(e);
                        }}
                      />
                      <InputField
                        label="app.shared.lastName"
                        name="lastName"
                        isValid={formik.touched.lastName && !formik.errors.lastName}
                        value={formik.values.lastName != null ? formik.values.lastName : ''}
                        errorMsg={
                          formik.touched.lastName && formik.errors.lastName
                            ? formik.errors.lastName
                            : ''
                        }
                        onChange={(e) => {
                          formik.setFieldValue('lastName', e.target.value);
                          formik.handleChange(e);
                        }}
                      />
                      <InputField
                        label="app.shared.mobile.number"
                        name="phoneNo"
                        helperText={''}
                        errorMsg={
                          formik.touched.phoneNo && formik.errors.phoneNo
                            ? formik.errors.phoneNo
                            : ''
                        }
                        minLength={9}
                        isValid={formik.touched.phoneNo && !formik.errors.phoneNo}
                        value={formik.values.phoneNo != null ? formik.values.phoneNo : NaN}
                        onChange={(e) => {
                          formik.setFieldValue('phoneNo', e.target.value);
                          formik.handleChange(e);
                        }}
                      />

                      <InputField
                        label="app.shared.email.requierd"
                        name="email"
                        isValid={formik.touched.email && !formik.errors.email}
                        value={formik.values.email != null ? formik.values.email : ''}
                        errorMsg={
                          formik.touched.email && formik.errors.email ? formik.errors.email : ''
                        }
                        onChange={(e) => {
                          formik.setFieldValue('email', e.target.value);
                          formik.handleChange(e);
                        }}
                      />
                    </GridView>
                  )}
                  {activeIndex === 1 && (
                    <GridView column={'1'}>
                      <div className="flex gap-6 mobile:flex-col tablet:flex-col">
                        <InputField
                          label="app.shared.position"
                          name="position"
                          isValid={formik.touched.position && !formik.errors.position}
                          value={formik.values.position != null ? formik.values.position : ''}
                          errorMsg={
                            formik.touched.position && formik.errors.position
                              ? formik.errors.position
                              : ''
                          }
                          onChange={(e) => {
                            formik.setFieldValue('position', e.target.value);
                            formik.handleChange(e);
                          }}
                        />
                        <InputField
                          label="app.shared.company"
                          name="company"
                          isValid={formik.touched.company && !formik.errors.company}
                          value={formik.values.company != null ? formik.values.company : ''}
                          errorMsg={
                            formik.touched.company && formik.errors.company
                              ? formik.errors.company
                              : ''
                          }
                          onChange={(e) => {
                            formik.setFieldValue('company', e.target.value);
                            formik.handleChange(e);
                          }}
                        />
                      </div>
                      <SelectComponent
                        selectLabel="app.shared.partnerShipType"
                        options={
                          i18n.language === 'en' ? list.partnershiptype_en : list.partnershiptype
                        }
                        value={
                          formik.values.partnershiptype != null ? formik.values.partnershiptype : ''
                        }
                        errorMsg={
                          formik.touched.partnershiptype && formik.errors.partnershiptype
                            ? formik.errors.partnershiptype
                            : ''
                        }
                        onChange={(e) => {
                          formik.setFieldValue('partnershiptype', e.target.value);
                          formik.handleChange(e);
                        }}
                        name="partnershiptype"
                      />
                    </GridView>
                  )}
                </form>
              </Formik>
            </div>

            <SharedButtonComponent
              isLoading={isLoading}
              isLastSection={isLastSection}
              activeIndex={activeIndex}
              handleBackClick={handleBackClick}
              onClick={() => {
                if (activeIndex === 0 || activeIndex === 1) {
                  handleNext(fieldsToCheck, formik, setActiveIndex, content);
                }
                if (isLastSection) {
                  formik.handleSubmit();
                }
              }}
            />
          </div>
        </RegistrationCardComponent>
      </div>

      {successfully && (
        <div
          className={`z-50 absolute h-full inset-0 ${
            i18n.language === 'en' ? 'left-80 mobile:left-0' : 'right-80 mobile:right-0'
          } mobile:top-0 mobile:left-0 tablet:top-80 `}
        >
          <div className="flex justify-center mx-auto items-center h-full">
            <Popup
              body="popup-successfully"
              onClose={() => {
                setSuccessfully(false);
                formik.resetForm();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BeOurPartnerFormContainer;
