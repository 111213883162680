import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/ui/Button';
import GridView from 'src/components/common/ui/grid-view';
import CVCardComponent from 'src/components/user/V2/CV-card-component';
import { SizesEnum } from 'src/core/enums/ui.enums';
import { VariantsEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { getCVService, putCVService } from 'src/services/CV.service';
interface Props {
  onEdit: (id: string) => void;
  onAdd: () => void;
}

export default function EducationView({ onEdit, onAdd }: Props) {
  const { data, setUserData } = useProfileStore();
  const { t } = useTranslation();
  const handleEdit = (id: string) => {
    onEdit(id);
  };

  const handleDelete = async (id: string) => {
    try {
      const updatedData = data?.education?.filter((item) => item.id !== id);
      await putCVService({
        ...data,
        education: updatedData,
      });
      const response = await getCVService();
      setUserData(response);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div className="flex flex-col gap-4 h-full w-full">
      <div className="grid order-first justify-end items-start w-full mobile:flex tablet:flex">
        {/* content tranlsation missing from BA*/}
        <Button variant={VariantsEnum.Filled} size={SizesEnum.Small} onClick={() => onAdd()}>
          {t('container.education.add')}
        </Button>
      </div>
      <GridView column="2">
        {data?.education?.map((item) => (
          <CVCardComponent
            key={item.id}
            title={item.institution || ''}
            title1={item.degree || ''}
            title2={item.grade || ''}
            onEdit={() => handleEdit(item.id || '')}
            onDelete={() => handleDelete(item.id || '')}
          />
        ))}
      </GridView>
    </div>
  );
}
