import { onlyLetters_Ar_En } from 'src/core/statics/regex';
import * as Yup from 'yup';

// Common string validation with letters and no-spaces check
const stringWithLettersSchema = () =>
  Yup.string()
    .required('app.shared.validation.required')
    .matches(onlyLetters_Ar_En, 'form.validation.name.no.special.chars')
    .test('no-only-spaces', 'app.shared.validation.required', (value) =>
      value ? value.trim().length > 0 : false
    );

export const validationSchema = () =>
  Yup.object({
    full_name: stringWithLettersSchema(),
    email: Yup.string()
      .required('app.shared.validation.required')
      .email('app.shared.validation.required'),
    organization: stringWithLettersSchema(),
    title: stringWithLettersSchema(),
    role: Yup.string().required('app.shared.validation.required'),
  });
