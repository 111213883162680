import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import SelectComponent2, { optionsList } from 'src/components/common/ui/CV-select-component';
import DateField from 'src/components/common/ui/DateField2';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import SaveCancelButton from 'src/components/user/V2/save-cancel-button';
import { Country, Degree, DegreeAr, GradeScale } from 'src/core/enums/profile.enum';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { EducationType } from 'src/core/types/CV.type';
import { getCVService, putCVService } from 'src/services/CV.service';

import { useEducationData } from './useEducationData';
import { cvInitialValues } from '../initialValues';
import { educationSchema } from '../validation.schema';

export default function EducationForm({
  setIsEducation,
  selectedId,
}: {
  setIsEducation: (value: boolean) => void;
  selectedId?: string;
}) {
  const { educationData, setEducationData } = useEducationData(selectedId);
  const { i18n } = useTranslation();
  const { data, setUserData } = useProfileStore();
  const CountryOptionsList: optionsList[] = Object.entries(Country).map(([key, value]) => ({
    value: value,
    key: key,
  }));
  const DegreeOptionsList: optionsList[] = Object.entries(Degree).map(([key, value]) => ({
    value: value,
    key: key,
  }));
  const DegreeAROptionsList: optionsList[] = Object.entries(DegreeAr).map(([key, value]) => ({
    value: value,
    key: key,
  }));
  const GradeOptionsList: optionsList[] = Object.entries(GradeScale).map(([key, value]) => ({
    value: value,
    key: key,
  }));

  const handleSubmit = async (values: Partial<EducationType>) => {
    try {
      const updatedEducation = [
        ...(data?.education || []),
        {
          ...values,
          country: values.country || null,
          completion_date: values.completion_date || null,
          gpa_list: values.gpa_list || null,
        },
      ];
      await putCVService({
        ...data,
        education: [...updatedEducation],
      });
      const response = await getCVService();
      setEducationData(response);
      setUserData(response);
      handleRequestError.success('container.CV.confirmation.saved');
      setIsEducation(false);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <Formik
      initialValues={
        educationData ??
        ({
          ...cvInitialValues.education?.[0],
        } as Partial<EducationType>)
      }
      enableReinitialize
      validationSchema={educationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, errors, touched, resetForm }) => (
        <Form className="flex flex-col gap-y-4 px-7 mobile:px-0 py-5 justify-between">
          <GridView column="1">
            <InputField
              label={'container.CV.Institution'}
              name="institution"
              isValid={touched.institution && !errors.institution}
              value={values.institution}
              onChange={handleChange}
              error={!!errors.institution}
              errorMsg={errors.institution}
            />
            <SelectComponent2
              selectLabel="app.shared.country"
              name="country"
              isValid={touched.country && !errors.country}
              value={values.country === null ? '' : values.country}
              onChange={handleChange}
              errorMsg={errors.country}
              options={CountryOptionsList}
            />
            <InputField
              label="container.CV.City"
              name="city"
              isValid={touched.city && !errors.city}
              value={values.city}
              onChange={handleChange}
              error={!!errors.city}
              errorMsg={errors.city}
            />
            <InputField
              label="container.TargetJobContainer.specialization"
              name="major"
              isValid={touched.major && !errors.major}
              value={values.major}
              onChange={handleChange}
              error={!!errors.major}
              errorMsg={errors.major}
            />
            <SelectComponent2
              selectLabel="container.CV.Degree"
              name="degree"
              isValid={touched.degree && !errors.degree}
              value={values.degree}
              onChange={handleChange}
              errorMsg={errors.degree}
              options={i18n.language === 'ar' ? DegreeAROptionsList : DegreeOptionsList}
            />
            <GridView column="2">
              <SelectComponent2
                selectLabel="container.CV.Grade"
                name="gpa_list"
                isValid={touched.gpa_list && !errors.gpa_list}
                value={values.gpa_list ?? ''}
                onChange={handleChange}
                errorMsg={errors.gpa_list}
                options={GradeOptionsList}
              />
              <InputField
                label="container.CV.Grade.example"
                name="grade"
                isValid={touched.grade && !errors.grade}
                value={values.grade}
                onChange={handleChange}
                error={!!errors.grade}
                errorMsg={errors.grade}
              />
            </GridView>
            {/* no content is provided for this field  from BA*/}
            <DateField
              name="completion_date"
              label="app.shared.endDate"
              acceptPastDate
              acceptFutureDate
              onChange={handleChange}
              value={values.completion_date ?? ''}
              isValid={touched.completion_date && !errors.completion_date}
              errorMsg={errors.completion_date}
            />
          </GridView>
          <SaveCancelButton
            onCancel={() => {
              resetForm();
              setIsEducation(false);
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
