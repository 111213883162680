import { userName, phone, password, onlyLetters_Ar_En } from 'src/core/statics/regex';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(onlyLetters_Ar_En, 'Name cannot contain special characters.'),

  username: Yup.string()
    .required('Username is required')
    .matches(userName, 'form.validation.first.userName'),
  email: Yup.string().required('Email is required').email('Invalid email format'),

  mobile: Yup.string()
    .required('Phone number is required')
    .matches(phone, 'Please enter a valid phone number.'),
});

export const VerifyPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required('validation.required'),
});

export const NewPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('app.shared.validation.required')
    .min(8, 'form.validation.resetPassword.min.8')
    .matches(password, 'form.validation.resetPassword.conditions'),
  new_password: Yup.string()
    .oneOf([Yup.ref('password')], 'form.error.resetConfirmPassword.matching')
    .required('app.shared.validation.required'),
});

export const OTPValidationSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required').length(6, 'OTP must be 6 digits'),
});
