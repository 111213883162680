import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/ui/Button';
import GridView from 'src/components/common/ui/grid-view';
import CVCardComponent from 'src/components/user/V2/CV-card-component';
import { SizesEnum, VariantsEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import getLanguageBasedClassName from 'src/core/helpers/languageBasedClassName';
import useProfileStore from 'src/core/stores/setUserData';
import { getCVService } from 'src/services/CV.service';
import { putCVService } from 'src/services/CV.service';

interface Props {
  onEdit: (id: string) => void;
  onAddCertificate: () => void;
  onAddTraining: () => void;
}

export default function TrainingAndCertificationView({
  onEdit,
  onAddCertificate,
  onAddTraining,
}: Props) {
  const { t } = useTranslation();
  const { data, setUserData } = useProfileStore();

  const handleEdit = (id: string) => {
    onEdit(id);
  };

  const handleDelete = async (id: string) => {
    try {
      const certificationsUpdatedData = data?.certificate?.filter((item) => item.id !== id);
      const trainingUpdatedData = data?.training?.filter((item) => item.id !== id);
      await putCVService({
        ...data,
        certificate: certificationsUpdatedData,
        training: trainingUpdatedData,
      });
      const response = await getCVService();
      setUserData(response);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div className="flex flex-col gap-4 h-full w-full">
      <div className="flex flex-row w-full">
        <div
          className={`flex gap-4 mobile:ml-auto mobile:mr-auto mobile:w-full mobile:flex-col ${getLanguageBasedClassName('ml-auto', 'mr-auto')}`}
        >
          <Button variant={VariantsEnum.Filled} size={SizesEnum.Small} onClick={onAddTraining}>
            {t('container.TrainingAndCertificationView.addTraining')}
          </Button>
          <Button variant={VariantsEnum.Outline} size={SizesEnum.Small} onClick={onAddCertificate}>
            {t('container.TrainingAndCertificationView.addCertification')}
          </Button>
        </div>
      </div>
      <GridView column="2">
        {data?.certificate?.map((item) => (
          <CVCardComponent
            key={item.id}
            title={item.name || ''}
            title1={item.issued_in || ''}
            title2={item.valid_until || ''}
            onEdit={() => handleEdit(item.id || '')}
            onDelete={() => handleDelete(item.id || '')}
          />
        ))}
        {data?.training?.map((item) => (
          <CVCardComponent
            key={item.id}
            title1={item.name || ''}
            title={item.institute || ''}
            onEdit={() => handleEdit(item.id || '')}
            onDelete={() => handleDelete(item.id || '')}
          />
        ))}
      </GridView>
    </div>
  );
}
